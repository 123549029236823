import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomerCreditsManager from './CustomerCreditsManager';
import { fetchAllCredits } from '../../../../services/store/features/credits/actions';

const mapStateToProps = (state) => ({
  data: state.credits.credits,
  status: state.credits.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllCredits }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCreditsManager);
