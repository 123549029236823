import React, { useState, useEffect } from 'react';

import * as statuses from '../../../../services/store/states';
import AccountLoading from '../../../../components/library/Loading';
import InvoiceManagerTable from './InvoiceManagerTable';
import Pagination from '../../../../components/Pagination';
import styles from './InvoiceManager.module.css';

export default function InvoiceManager({
  project = null, status = statuses.IDLE, invoices = undefined, invoicesToExport = [],
  filter = undefined, query = '', isLoading = false, actions = {}, handleInvoicesToExport = undefined,
  showPagination = true, maxPerPage = 10,
}) {
  const [page, setPage] = useState(1);
  useEffect(() => {
    actions.fetchAllInvoices({
      filter, query, page, project, maxPerPage,
    });
  }, [filter, query, page, project]);

  if (status === statuses.IDLE || isLoading) return <AccountLoading />;

  if (status === statuses.FAIL || invoices.invoices.length === 0) {
    return (
      <div className={styles.error}>
        <p> No invoices to display </p>
      </div>
    );
  }

  return (
    <>
      <InvoiceManagerTable
        data={invoices.invoices}
        invoicesToExport={invoicesToExport}
        handleInvoicesToExport={handleInvoicesToExport}
      />
      {
        (showPagination)
        && (
          <Pagination
            currentPage={page}
            total={invoices.total}
            setPage={setPage}
          />
        )
      }
    </>
  );
}
