import axios from '../axiosClient';
import domains from '../../../global';

const setSearchParamsToUrl = (url, {
  project, filter, query, maxPerPage, page,
}) => {
  if (project) {
    url.searchParams.append('search', project);
  }
  if (filter) {
    url.searchParams.append('not_payed_invoice', ((filter === 'paid') ? '1' : '0'));
  }
  if (query && query.trim() !== '') {
    url.searchParams.append('search', query);
  }
  if (maxPerPage) {
    // bug in backend with pagination, put 50 to retrieve all
    url.searchParams.append('per_page', 50);
  }
  if (page) {
    url.searchParams.append('page', page);
  }
};

export const getAllInvoices = (page, filter, query, project, maxPerPage) => {
  const url = new URL(`${window.location.origin}${domains.url}/invoices/all`);
  setSearchParamsToUrl(url, {
    page, filter, query, project, maxPerPage,
  });
  return axios.get(url.href);
};

export const getAllInvoicesByProject = (page, project) => axios.get(`/invoices/all?project=${project}&page=${page}`);

export const updateInvoiceAsPaid = (invoice, projectId, reason) => axios.post('/invoice/set_to_payed', { id_ayomi: projectId, products_invoice_id: [invoice], reason });

export const exportInvoices = (arrayOfInvoicesId) => axios.get('/invoices/export', { products_invoices_id: arrayOfInvoicesId });

export const exportCredits = (arrayOfCreditsId) => axios.get('/discount/export', { products_invoices_id: arrayOfCreditsId });

export const generatePaymentLink = (value) => axios.post('/invoice/create_payment_link', value);

export const getAllCredits = (page, filter, query, project) => {
  const url = new URL(`${window.location.origin}${domains.url}/discount`);
  setSearchParamsToUrl(url, {
    page, query, project, filter,
  });
  return axios.get(url.href);
};

export const createNewCredit = (value) => axios.post('/discount', value);

export const getAllCustomers = (page, query) => {
  const url = new URL(`${window.location.origin}${domains.url}/project?page=${page}`);
  if (query && query.trim() !== '') {
    url.searchParams.append('search', query);
  }
  return axios.get(url.href);
};

export const getAllInvestorsById = (id) => axios.get(`/invest/${id}`);

export const sendRefundRequest = (data) => axios.post('/refund_request', data);

export const generateInvoiceByUrl = (url) => axios.get(url, { responseType: 'blob' });

export const getAllRefundRequest = () => axios.get('/refund_request');

export const refund = (data) => axios.post('/refund_request', data);
