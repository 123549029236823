import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Toasts from './Toasts';
import { getAutoDeleteTime, getToasts } from '../../../services/store/features/toasts/selectors';
import { removeToast } from '../../../services/store/features/toasts/actions';

const mapStateToProps = (state) => ({
  list: getToasts(state),
  autoDeleteTime: getAutoDeleteTime(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ removeToast }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
