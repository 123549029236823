import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowForward } from '@material-ui/icons';
import Pagination from '../../../../components/Pagination';
import Loading from '../../../../components/library/Loading';
import * as statuses from '../../../../services/store/states';
import Table from '../../../../components/Table';
import styles from './CustomersManager.module.css';

export default function CustomersManager({
  status = statuses.IDLE, isLoading = false, actions = {},
  total = 0, query = '', customers = [],
}) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    actions.fetchAllCustomers(query, page);
  }, [page, query]);

  if (isLoading || status === statuses.IDLE) {
    return <Loading />;
  }
  if (status !== statuses.SUCCESS) {
    return (
      <div className={styles.empty}>
        <p>
          No projects available
        </p>
      </div>
    );
  }
  return (
    <>
      <div className={styles.table}>
        <Table
          data={customers}
          columns={[
            { label: 'Customer', value: 'fullname', type: 'alpha' },
            { label: 'Email', value: 'email', type: 'alpha' },
            { label: 'Project', value: 'name', type: 'num' },
          ]}
        >
          { (customersList) => customersList && customersList.map((customer) => (
            <div key={customer.id}>
              <p className={styles.bold}>{customer.fullname}</p>
              <p>{customer.email}</p>
              <p>{`${customer.id} - ${customer.name}`}</p>
              <NavLink
                to={`/admin/customers/${customer.id}`}
                className={styles.button}
              >
                <ArrowForward />
              </NavLink>
            </div>
          )) }
        </Table>
      </div>
      <Pagination
        currentPage={page}
        total={total}
        setPage={setPage}
      />
    </>
  );
}
