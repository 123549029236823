import React from 'react';
import Toolbar from '../../../../components/Toolbar';
import { exportInvoices } from '../../../../services/network/api/invoices';
import InvestmentsManager from './InvestmentsManager';

export default function Investments() {
  return (
    <Toolbar getExport={exportInvoices}>
      {
        (query, invoices, handleInvoicesToExport) => (
          <InvestmentsManager
            query={query}
            invoicesToExport={invoices}
            handleInvoicesToExport={handleInvoicesToExport}
          />
        )
      }
    </Toolbar>
  );
}
