import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CreditCreation from './CreditCreation';
import { createCredit } from '../../services/store/features/invoices/actions';

const mapStateToProps = (state) => ({
  invoices: state.invoices.invoices.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ createCredit }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCreation);
