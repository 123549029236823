import React from 'react';
import CreditLayout from './CreditLayout';
import Toolbar from '../../../components/Toolbar';
import { exportCredits } from '../../../services/network/api/invoices';
import CreditManager from './CreditManager';

export default function Credit() {
  return (
    <CreditLayout>
      {
        (filter) => (
          <Toolbar getExport={exportCredits}>
            {
              (query, credits, handleCreditsToExport) => (
                <CreditManager
                  filter={filter}
                  query={query}
                  handleCreditsToExport={handleCreditsToExport}
                  creditsToExport={credits}
                />
              )
            }
          </Toolbar>
        )
      }
    </CreditLayout>
  );
}
