import React, { useState } from 'react';

import Button from '../library/Button/Button';
import styles from './RefundCreation.module.css';

export default function RefundCreation({
  selected, project, close, actions,
}) {
  const [status, setStatus] = useState('IDLE');
  const [error, setError] = useState();
  const submit = async () => {
    actions.ayomiRefund(selected, project, setStatus, setError, close);
  };
  return (
    <>
      <header>
        <h1>
          Are you sure you want to refund ?
        </h1>
        <p>
          Ayomi will refund the investment without fees for the client.
        </p>
      </header>
      {
        (status === 'ERROR')
        && (
          <p className={styles.hintError}>
            {error}
          </p>
        )
      }
      <Button
        label="Refund"
        onButtonClick={submit}
        disabled={status === 'LOADING'}
      />
      {
        (status === 'IDLE' || status === 'ERROR')
        && (
          <Button
            label="Cancel"
            variant="ghost"
            className={styles.cancel}
            onButtonClick={close}
          />
        )
      }
    </>
  );
}
