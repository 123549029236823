import React from 'react';
import Layout from '../../../../components/Layout';

export default function CreditLayout({ children }) {
  return (
    <Layout
      title="Credits"
      buttons={[
        {
          label: 'All credits',
          value: null,
        },
      ]}
    >
      { children }
    </Layout>
  );
}
