import React from 'react';

import styles from './InvoiceManagerDataProduct.module.css';

export default function InvoiceManagerDataProduct({ products }) {
  return (
    <section className={`fadeIn ${styles.products}`}>
      <div className={styles.title}>
        <p>Product</p>
        <p>Amount</p>
        <p>Date</p>
        <p>Status</p>
      </div>
      {
          products.map((product) => (
            <div
              key={product.id}
              className={styles.row}
            >
              <p>{product.bill_label}</p>
              <p>{product.amount}</p>
              <p>{(new Date(product.issue_date)).toLocaleDateString()}</p>
              <p>{product.payment_status}</p>
            </div>
          ))
       }
    </section>
  );
}
