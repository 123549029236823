import React, { useRef } from 'react';
import { Receipt, Send } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles
  from '../../../../../Invoice/InvoiceManager/InvoiceManagerTable/InvoiceManagerData/InvoiceManagerDataDropdown/InvoiceManagerDataDropdown.module.css';
import useOutsideClickAlert from '../../../../../../../components/library/hook/clickOutside';
import { addToast } from '../../../../../../../services/store/features/toasts/actions';
import { sendRefundRequest } from '../../../../../../../services/network/api/invoices';
import RefundCreation from '../../../../../../../components/RefundCreation';

export default function InvestmentsManagerDataDropdown({
  investment, refundRequest, close, actions,
}) {
  const dropdown = useRef();
  useOutsideClickAlert(dropdown, close);

  const dispatch = useDispatch();
  const { id: project } = useParams();

  const refund = () => {
    actions.addPopup({
      children: (closePopup) => (
        <RefundCreation
          close={closePopup}
          selected={investment.id_stripe}
          project={project}
        />
      ),
    });
    close();
  };

  const askRefund = async () => {
    try {
      await sendRefundRequest({
        investment_id: investment.id_stripe,
        id_ayomi: project,
        action: 'creation',
        is_ayomi: false,
      });
      dispatch(addToast({
        type: 'success',
        title: 'Request has been created successfully',
        description: 'Please, inform the project holder to visit the invoicing space',
      }));
      dispatch({
        type: 'CREATE_REFUND_REQUEST',
        payload: {
          id: investment.id_stripe,
          is_ayomi: false,
          is_validated: false,
        },
      });
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Request has failed',
        description: `Request failed due to ${err}`,
      }));
    } finally {
      close();
    }
  };

  return (
    <div className={styles.dropdown} ref={dropdown}>
      <button
        type="button"
        onClick={refund}
        disabled={investment.status === 'refunded'}
      >
        <Receipt className={styles.icon} />
        Refund Ayomi
      </button>
      <button
        type="button"
        onClick={askRefund}
        disabled={investment.status === 'refunded' || refundRequest[investment.id_stripe]}
      >
        <Send className={styles.icon} />
        Send refund request
      </button>
    </div>
  );
}
