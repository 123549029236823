import React, { useState } from 'react';
import { ArrowBack, Search } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import styles from './Layout.module.css';

export default function Layout({
  children, title, buttons, showSearchbar = false, hasBackButton = false,
}) {
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState(null);
  return (
    <section className={styles.project}>
      <header
        className={styles.header}
        style={(showSearchbar) ? { paddingBottom: '16px' } : null}
      >
        {
          (hasBackButton)
          && (
            <Link
              className={styles.link}
              to="/admin/customers"
            >
              <ArrowBack />
              <span>Back to customers&apos; list</span>
            </Link>
          )
        }
        <h1 className={styles.title}>{ title }</h1>
        <nav>
          {
            (!showSearchbar)
            && buttons.map((button) => (
              <button
                key={button.value}
                type="button"
                className={`${styles.button} ${(filter === button.value) ? styles.selected : ''}`}
                onClick={() => setFilter(button.value)}
              >
                { button.label }
              </button>
            ))
          }
          {
            (showSearchbar)
            && (
              <>
                <div className={styles.searchbar}>
                  <Search className={styles.icon} />
                  <input
                    type="text"
                    value={query}
                    placeholder="Search by name, email or project ..."
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setFilter(query);
                      }
                    }}
                  />
                </div>
                {
                  Number.isInteger(Number.parseInt(query, 10))
                  && (
                    <Link
                      className={styles.link}
                      to={`/admin/customers/${Number.parseInt(query, 10)}`}
                    >
                      See invoices linked to projet
                      {' '}
                      {Number.parseInt(query, 10)}
                    </Link>
                  )
                }
              </>
            )
          }
        </nav>
      </header>
      {
        children(filter)
      }
    </section>
  );
}
