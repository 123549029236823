import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvoiceStatus from './InvoiceStatus';
import { resetInvoice, updateInvoice } from '../../services/store/features/invoices/actions';

const mapStateToProps = (state) => ({
  invoices: state.invoices.invoices.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateInvoice, resetInvoice }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStatus);
