import React, { useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';

import styles from './Toasts.module.css';

function Toast({ item, autoDeleteTime, actions }) {
  const [time, setTime] = useState(0);
  const [unmountTransition, setUnmountTransition] = useState(false);

  useEffect(() => {
    const setTimeInterval = setInterval(
      () => setTime((state) => state + 40),
      40,
    );
    const interval = setInterval(() => {
      clearInterval(setTimeInterval);
      setUnmountTransition(true);
    }, autoDeleteTime);
    return () => {
      clearInterval(interval);
      clearInterval(setTimeInterval);
    };
  }, []);

  return (
    <div
      className={`${styles.toast} ${(unmountTransition) ? styles.hideToast : ''}`}
      onAnimationEnd={() => {
        if (unmountTransition) {
          actions.removeToast(item.id);
        }
      }}
    >
      <div
        className={styles.time}
        style={{
          backgroundColor: item.color,
          width: `${(time * 100) / autoDeleteTime}%`,
        }}
      />
      <div className={styles.content}>
        <i style={{ color: item.color }}>
          { item.icon }
        </i>
        <div className={styles.textContainer}>
          <p className={styles.title}>
            { item.title }
          </p>
          <p className={styles.description}>
            { item.description }
          </p>
        </div>
        <button
          type="button"
          className={styles.button}
          onClick={() => setUnmountTransition(true)}
        >
          <Close className={styles.icon} />
        </button>
      </div>
    </div>
  );
}

export default function Toasts({ list, autoDeleteTime, actions }) {
  return (
    <div className={styles.toastContainer}>
      {
        list && list.map((toast) => (
          <Toast
            key={toast.id}
            item={toast}
            autoDeleteTime={autoDeleteTime}
            actions={actions}
          />
        ))
      }
    </div>
  );
}
