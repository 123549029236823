import React, { useState, useEffect } from 'react';
import { Error, FileCopy } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import styles from './PaymentLink.module.css';
import Button from '../library/Button/Button';
import * as statuses from '../../services/store/states';
import { generatePaymentLink } from '../../services/network/api/invoices';
import Loading from '../library/Loading';
import copyTextToClipboard from '../../utils/copyToClipboard';
import InputText from '../library/Input/InputText/InputText';

export default function PaymentLink({
  userEmail, id, arrayOfInvoicesId, amount, close, redirected = false,
}) {
  const [email, setEmail] = useState(userEmail);
  const [linkState, setLinkState] = useState({ status: statuses.IDLE, error: null, url: null });
  const [isCopy, setIsCopy] = useState(false);

  const { t } = useTranslation();

  const getPaymentLink = async (value) => {
    setLinkState((state) => ({ ...state, status: statuses.LOADING }));
    try {
      const payment = {
        products_invoices_id: arrayOfInvoicesId,
        email: value,
        type: '',
        amount,
        id_project: id,
      };
      const { data } = await generatePaymentLink(payment);
      setLinkState({ status: statuses.SUCCESS, error: null, url: data.url });
      if (redirected) {
        window.location.assign(data.url);
      }
    } catch (error) {
      setLinkState({
        status: statuses.FAIL,
        error: error?.response?.data?.action || error,
        url: null,
      });
    }
  };

  useEffect(() => {
    if (redirected) {
      getPaymentLink(userEmail);
    }
  }, []);

  const handleUrlCopy = () => {
    const onSuccess = () => {
      setIsCopy(true);
      window.setTimeout(() => setIsCopy(false), 1000);
    };
    const onError = () => {
      setIsCopy(false);
    };
    copyTextToClipboard(linkState.url, onSuccess, onError);
  };

  if (linkState.status === statuses.IDLE) {
    return (
      <>
        <header className={styles.header}>
          <h1>
            Generate a payment link
          </h1>
          <p>
            Send this link to your customer. Be careful, this link expires in 24h.
          </p>
        </header>
        <main>
          <p>
            Please, enter the email of the user for which you generate the link
          </p>
          <InputText
            label="Email"
            valueInput={email}
            onTextChange={(e) => setEmail(e.target.value)}
          />
          <Button
            label="Generate payment link"
            onButtonClick={() => getPaymentLink(email)}
          />
        </main>
      </>
    );
  }

  if (redirected) {
    return (
      <>
        {
          (linkState.status === statuses.LOADING || linkState.status === statuses.SUCCESS)
          && (
            <>
              <header className={styles.header}>
                <h1>
                  {t('payment.redirect')}
                </h1>
              </header>
              <div className={styles.loading}>
                <Loading />
              </div>
            </>
          )
        }
        {
          (linkState.status === statuses.FAIL)
          && (
            <>
              <header className={styles.header}>
                <h1>
                  {t('payment.error.title')}
                </h1>
                <p>
                  {t('payment.error.description')}
                </p>
              </header>
              <Button
                label={t('payment.error.close')}
                onButtonClick={close}
              />
            </>
          )
        }
      </>
    );
  }

  return (
    <>
      <header className={styles.header}>
        <h1>
          Generate a payment link
        </h1>
        <p>
          Send this link to your customer. Be careful, this link expires in 24h.
        </p>
      </header>
      {
        (linkState.status === statuses.LOADING)
        && <Loading />
      }
      {
        (linkState.status === statuses.FAIL)
        && (
          <p className={styles.error}>
            <Error className={styles.icon} />
            {`An error occured : ${linkState.error}`}
          </p>
        )
      }
      {
        (linkState.status === statuses.SUCCESS)
        && (
          <>
            <Button
              type="custom"
              label={(!isCopy) ? 'Copy the url' : 'Copied !'}
              variant="ghost"
              icon={<FileCopy />}
              className={styles.custom}
              disabled={isCopy}
              onButtonClick={handleUrlCopy}
            />
            <p className={styles.url}>{ linkState.url }</p>
          </>
        )
      }
      <Button
        label="Close"
        onButtonClick={close}
      />
    </>
  );
}
