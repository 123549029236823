import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import styles from './Pagination.module.css';

export default function Pagination({
  currentPage, maxPerPage = 10, total, setPage, className = '',
}) {
  const [value, setValue] = useState(currentPage);
  const [isEdit, setIsEdit] = useState(false);

  const inputRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const handleEdition = () => {
    setIsEdit(true);
  };

  if (total <= 10) return null;

  return (
    <div className={`${styles.footer} ${className}`}>
      <p>{t('pagination.pages', { pageFirstItem: maxPerPage * (currentPage - 1) + 1, pageLastItem: maxPerPage * currentPage, total })}</p>
      <div className={styles.pages}>
        <p className={styles.description}>
          {t('pagination.position')}
        </p>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(Number.parseInt(e.target.value, 10))}
          onBlur={(e) => {
            if (e.target.value === '' || e.target.value === '0') {
              setPage(1);
            }
            if (e.target.value > (total / maxPerPage)) {
              setPage(total / maxPerPage);
            }
            setIsEdit(false);
          }}
          min={1}
          max={(total) ? total / maxPerPage : 0}
          className={styles.input}
          hidden={!isEdit}
          ref={inputRef}
        />
        <button
          type="button"
          className={styles.activePage}
          onClick={handleEdition}
          hidden={isEdit}
        >
          { currentPage }
        </button>
        <button
          type="button"
          className={`${styles.arrowButton} ${styles.left}`}
          onClick={() => setPage((page) => Number.parseInt(page, 10) - 1)}
          disabled={currentPage === 1}
        >
          <ArrowBack className={styles.icon} />
        </button>
        <button
          type="button"
          className={`${styles.arrowButton} ${styles.right}`}
          onClick={() => setPage((page) => Number.parseInt(page, 10) + 1)}
          disabled={currentPage === (total / maxPerPage)}
        >
          <ArrowForward className={styles.icon} />
        </button>
      </div>
    </div>
  );
}
