import React from 'react';
import Layout from '../../../../components/Layout';

export default function InvoiceLayout({ children }) {
  return (
    <Layout
      title="Invoices"
      buttons={[
        {
          label: 'All invoices',
          value: null,
        },
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'In wait',
          value: 'wait',
        },
      ]}
    >
      { children }
    </Layout>
  );
}
