import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomerInvoiceData from './CustomerInvoiceData';
import { setSelectedDocument } from '../../../../services/store/features/invoices/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setSelectedDocument }, dispatch),
});

export default connect(mapDispatchToProps)(CustomerInvoiceData);
