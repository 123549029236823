import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Description, MenuOpen, Person } from '@material-ui/icons';
import useOutsideClickAlert from '../library/hook/clickOutside';

import styles from './Navigation.module.css';

export default function Navigation() {
  const [showInvoice, setShowInvoice] = useState(false);
  const [open, setIsOpen] = useState(false);
  const routerLocation = useLocation();

  const navBarRef = useRef(null);
  useOutsideClickAlert(navBarRef, () => setIsOpen(false));

  const handleLocation = (match, location) => match && (location.pathname === '/admin' || location.pathname === '/admin/credits');

  useEffect(() => {
    if (routerLocation.pathname === '/admin' || routerLocation.pathname === '/admin/credits') {
      setShowInvoice(true);
    } else {
      setShowInvoice(false);
    }
    setIsOpen(false);
  }, [routerLocation]);

  return (
    <div>
      <nav
        className={`${styles.navigation} ${(open) ? styles.show : styles.hide}`}
        ref={navBarRef}
      >
        <div className={styles.logoContainer}>
          <p className={styles.logo}>
            Ayomi
            <span className={styles.dot}>
              .
            </span>
          </p>
          <p className={styles.logoDescription}>
            Invoices
          </p>
        </div>
        <div className={styles.general}>
          <p className={styles.title}>
            MENU
          </p>
          <NavLink
            to="/admin"
            isActive={handleLocation}
            className={styles.link}
            activeClassName={styles.selected}
          >
            <Description className={styles.icon} />
            Invoices
          </NavLink>
          {
            (showInvoice)
            && (
              <>
                <NavLink
                  to="/admin"
                  className={styles.subLink}
                  activeClassName={styles.active}
                  isActive={(match, location) => match && location.pathname === '/admin'}
                >
                  <span>
                    Invoices
                  </span>
                </NavLink>
                <NavLink
                  to="/admin/credits"
                  className={styles.subLink}
                  activeClassName={styles.active}
                >
                  <span>
                    Credits
                  </span>
                </NavLink>
              </>
            )
          }
          <NavLink
            to="/admin/customers"
            className={styles.link}
            activeClassName={styles.selected}
          >
            <Person className={styles.icon} />
            Customers
          </NavLink>
        </div>
      </nav>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={styles.menu}
      >
        <MenuOpen />
      </button>
    </div>
  );
}

Navigation.defaultProps = {};

Navigation.propTypes = {};
