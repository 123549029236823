import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table';
import Toolbar from '../../../components/Toolbar';
import Pagination from '../../../components/Pagination';
import CustomerInvoiceData from './CustomerInvoiceData';
import styles from './CustomerInvoice.module.css';

export default function CustomerInvoice({ data, actions }) {
  const [page, setPage] = useState('1');
  const { id } = useParams();

  useEffect(() => {
    actions.fetchAllInvoices({ project: id, filter: 'paid', page });
  }, [id, page]);

  const { t } = useTranslation();

  const getCurrentData = (queryToSearch, items) => items && items.filter(({
    id: invoiceId, amountTTC, amountHT, discount_amount: credits, project,
  }) => {
    const itemToString = `${invoiceId} ${amountTTC} ${amountHT} ${credits} ${project}`.toLowerCase();
    return itemToString.search(queryToSearch.toLowerCase()) !== -1;
  });

  if (!data?.invoices || data.invoices.length === 0) {
    return (
      <div className={styles.error}>
        <p> No invoices to display </p>
      </div>
    );
  }

  return (
    <Toolbar
      className={styles.toolbar}
    >
      {
        (query, idToExport, handleDataToExport) => (
          <div className={styles.table}>
            <Table
              data={data?.invoices || []}
              handleDataToExport={handleDataToExport}
              dataToExport={idToExport}
              columns={[
                { label: t('invoices.reference'), value: 'id', type: 'alpha' },
                { label: t('invoices.date'), value: 'date', type: 'num' },
                { label: t('invoices.amountHT'), value: 'amountHT', type: 'num' },
                { label: t('invoices.amountTTC'), value: 'amountTTC', type: 'num' },
                { label: t('invoices.credits'), value: 'discount_amount', type: 'num' },
                { label: t('invoices.status'), value: 'status', type: 'alpha' },
              ]}
            >
              {
                (currentData) => (
                  <CustomerInvoiceData
                    data={getCurrentData(query, currentData)}
                    handleDataToExport={handleDataToExport}
                    dataToExport={idToExport}
                  />
                )
              }
            </Table>
            <Pagination
              total={data?.total || 0}
              currentPage={page}
              setPage={setPage}
            />
          </div>
        )
      }
    </Toolbar>
  );
}
