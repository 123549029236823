import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Customer from './Customer';
import { fetchAllInvoices, setSelectedDocument } from '../../services/store/features/invoices/actions';
import { addPopup } from '../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  status: state.invoices.status,
  invoices: state.invoices.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllInvoices, setSelectedDocument, addPopup }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
