import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomerRefund from './CustomerRefund';
import { fetchAllInvestors } from '../../../services/store/features/investors/actions';

const mapStateToProps = (state) => ({
  ...state.investors.refunds,
  data: state.investors.investors.filter((invoice) => (
    (state.investors.refunds.data[invoice.id_stripe] !== undefined)
    && (state.investors.refunds.data[invoice.id_stripe].is_ayomi === false)
    && (state.investors.refunds.data[invoice.id_stripe].is_validated === false)
  )),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllInvestors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRefund);
