import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Table from '../../../components/Table';
import CustomerRefundData from './CustomerRefundData';

import styles from './CustomerRefund.module.css';
import * as statuses from '../../../services/store/states';

export default function CustomerRefund({ status, data, actions }) {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (status === statuses.IDLE) {
      actions.fetchAllInvestors(id);
    }
  }, []);

  const getFirstname = (value) => value.person.first_name;

  const getLastname = (value) => value.person.last_name;

  return (
    <div className={styles.refund}>
      <p>
        {t('refund.description')}
      </p>
      <p>
        {`${t('refund.number')}`}
        <span className={styles.number}>
          {data?.length || 0}
        </span>
      </p>
      <div className={styles.tableContainer}>
        <Table
          data={data || []}
          columns={[
            { label: t('refund.email'), value: 'email', type: 'alpha ' },
            {
              label: t('refund.firstname'),
              value: 'firstname',
              transform: getFirstname,
              type: 'alpha',
            },
            {
              label: t('refund.lastname'),
              value: 'lastname',
              transform: getLastname,
              type: 'alpha',
            },
            { label: t('refund.amount'), value: 'amount', type: 'num' },
            { label: t('refund.request'), value: '', type: 'alpha' },
          ]}
        >
          {
            (currentData) => (
              <CustomerRefundData
                data={currentData}
              />
            )
          }
        </Table>
      </div>
    </div>
  );
}
