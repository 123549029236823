import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvoiceManagerDataDropdown from './InvoiceManagerDataDropdown';
import { addPopup } from '../../../../../../../services/store/features/popup/actions';
import { setSelectedDocument } from '../../../../../../../services/store/features/invoices/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addPopup, setSelectedDocument }, dispatch),
});

export default connect(null, mapDispatchToProps)(InvoiceManagerDataDropdown);
