import React from 'react';
import styles from './Button.module.css';

export default function Button({
  type = 'secondary',
  size = 'medium',
  variant = 'fill',
  label = 'Button',
  buttonType = 'button',
  icon = null,
  disabled = false,
  onButtonClick,
  className,
  form,
  name,
}) {
  const getClass = () => `${styles.button} ${(disabled) ? styles.disabled : styles[type]} ${styles[size]} ${styles[variant]} ${(className) || ''}`;

  return (
    <button
      type={buttonType === 'button' ? 'button' : 'submit'}
      className={getClass()}
      onClick={onButtonClick}
      disabled={disabled}
      aria-label={label || name}
      form={form}
    >
      { (icon)
        && (
          <span className={styles.icon}>
            {icon}
          </span>
        )}
      { label }
    </button>
  );
}
