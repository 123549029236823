import * as types from './types';
import * as statuses from '../../states';

const initState = {
  status: statuses.IDLE,
  previousFetch: {},
  customers: {
    total: 0,
    list: [],
  },
};

export default function (state = initState, { type, payload }) {
  switch (type) {
    case types.FETCH_CUSTOMERS:
      return {
        ...state,
        customers: {
          total: payload.total,
          list: payload.customers,
        },
        status: statuses.SUCCESS,
        previousFetch: payload.fetch,
      };
    case types.FETCH_CUSTOMERS_FAILED:
      return { ...state, status: statuses.FAIL, error: payload };
    default:
      return state;
  }
}
