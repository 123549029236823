import React from 'react';

import InvoiceManagerData from './InvoiceManagerData';
import Table from '../../../../../components/Table';
import styles from './invoiceManagerTable.module.css';

export default function InvoiceManagerTable({
  data, invoicesToExport, handleInvoicesToExport,
}) {
  return (
    <section className={styles.table}>
      <Table
        data={data}
        dataToExport={invoicesToExport}
        handleDataToExport={handleInvoicesToExport}
        columns={[
          { label: 'Customer', value: 'customerName', type: 'alpha' },
          { label: 'Invoice ID', value: 'id', type: 'alpha' },
          { label: 'Amount excl tax', value: 'amountHT', type: 'num' },
          { label: 'Amount incl tax', value: 'amountTTC', type: 'num' },
          { label: 'Credits', value: 'discount_amount', type: 'num' },
          { label: 'Balance', value: 'balance', type: 'num' },
          { label: 'Status', value: 'status', type: 'alpha' },
          { label: 'Date', value: 'date', type: 'num' },
        ]}
      >
        {
          (currentData) => (
            <InvoiceManagerData
              data={currentData}
              invoicesToExport={invoicesToExport}
              handleInvoicesToExport={handleInvoicesToExport}
            />
          )
        }
      </Table>
    </section>
  );
}
