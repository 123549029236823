import * as types from './types';
import popupFactory from './popupFactory';

export function addPopup(properties) {
  return (dispatch) => {
    const popup = popupFactory(properties);
    dispatch({ type: types.ADD_POPUP, payload: popup });
  };
}

export function removePopup(id) {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_POPUP, payload: id });
  };
}
