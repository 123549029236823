/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  ArrowBack, ArrowForward, GetApp, Error,
} from '@material-ui/icons';

import styles from './DocumentsVisualisation.module.css';
import AccountLoading from '../library/Loading';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumentsVisualisation({
  selectedDocument, isLoading, actions, error, isAdmin,
}) {
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
  }
  return (
    <section className={`fadeIn ${styles.container} ${!isAdmin ? styles.customer : ''}`}>
      <header className={styles.header}>
        <div className={styles.titleContainer}>
          <button
            type="button"
            className={styles.link}
            onClick={() => actions.setSelectedDocument(null)}
          >
            <ArrowBack />
          </button>
          <h1>
            { selectedDocument.title }
          </h1>
        </div>
        <a
          className={styles.link}
          href={selectedDocument.url}
          download={selectedDocument.title}
          target="_blank"
          rel="noreferrer"
        >
          <GetApp />
        </a>
      </header>
      {
        (isLoading) && <AccountLoading />
      }
      {
        (error) && (
          <div className={styles.error}>
            <Error className={styles.icon} />
            <h1> Impossible d&apos;afficher le fichier </h1>
            <p>{ `${error}` }</p>
          </div>
        )
      }
      {
        (!isLoading && !error)
        && (
          <div>
            <Document
              file={selectedDocument.url}
              onLoadSuccess={onDocumentLoadSuccess}
              options={{ workerSrc: 'pdf.worker.js' }}
              className={styles.document}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            {
              (totalPages > 1)
              && (
                <div className={styles.pagination}>
                  <p className={styles.number}>
                    {`Page ${pageNumber} sur ${totalPages}`}
                  </p>
                  <div>
                    <button
                      type="button"
                      onClick={() => setPageNumber((state) => state - 1)}
                      className={(pageNumber === 1) ? styles.disabled : null}
                      disabled={(pageNumber === 1)}
                    >
                      <ArrowBack />
                    </button>
                    <button
                      type="button"
                      onClick={() => setPageNumber((state) => state + 1)}
                      className={(pageNumber === totalPages) ? styles.disabled : null}
                      disabled={(pageNumber === totalPages)}
                    >
                      <ArrowForward />
                    </button>
                  </div>
                </div>
              )
            }
          </div>
        )
      }
    </section>
  );
}
