import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

// import InputText from '../library/Input/InputText/InputText';
import TextArea from '../library/Input/TextArea/TextArea';
import Button from '../library/Button/Button';
import * as statuses from '../../services/store/states';
import Loading from '../library/Loading';

export default function InvoiceStatus({
  id, invoices, close, actions,
}) {
  const invoice = invoices.find((i) => i.id === id);
  const [amount] = useState(invoice.amountHT);
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    actions.updateInvoice({
      id: invoice.id, amount, reason, project: invoice.project,
    });
  };

  useEffect(() => {
    if (invoice.uiStatus !== statuses.IDLE && invoice.uiStatus !== statuses.LOADING) {
      actions.resetInvoice(id);
      close();
    }
  }, [invoices]);

  return (
    <>
      <header>
        <h1>
          Mark as paid
        </h1>
        <p>
          Mark the invoice as paid for the wished amount.
        </p>
      </header>
      {
        (invoice.uiStatus === statuses.IDLE)
        && (
          <>
            <p>
              Are you sure that you want to mark this invoice as paid ?
              <br />
              This action is not reversible
            </p>
            <TextArea
              label="Why do you want to mark this invoice as paid ?"
              valueInput={reason}
              onTextChange={(e) => setReason(e.target.value)}
            />
            <Button
              label="Mark as paid"
              type="primary"
              icon={(invoice.status === statuses.LOADING) ? <CircularProgress /> : <Warning />}
              onButtonClick={handleSubmit}
              disabled={(reason === '')}
            />
            <Button
              label="Cancel"
              variant="ghost"
              onButtonClick={close}
            />
          </>
        )
      }
      {
        (invoice.uiStatus === statuses.LOADING)
        && (
          <Loading />
        )
      }
    </>
  );
}
