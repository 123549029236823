import React, { useState } from 'react';
import { MoreVert } from '@material-ui/icons';
import Checkbox from '../../../../../../components/library/Input/Checkbox/Checkbox';
import InvestmentsManagerDataDropdown from './InvestmentsManagerDataDropdown';
import styles from '../../../../Invoice/InvoiceManager/InvoiceManagerTable/InvoiceManagerData/InvoiceManagerData.module.css';

function InvestmentsManagerDataRow({ investment, isChecked, onCheckedChange }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.row}>
      <Checkbox
        checked={isChecked}
        onChange={() => onCheckedChange([investment.invoice])}
      />
      <p className={styles.title} />
      <p className={styles.title}>Full name</p>
      <p>{ investment.fullname }</p>
      <p className={styles.title}>E-mail</p>
      <p>{ investment.email }</p>
      <p className={styles.title}>Phone</p>
      <p>{ investment.phone }</p>
      <p className={styles.title}>Status</p>
      <p className={`${styles.status} ${styles[investment.status]}`}>{ investment.status }</p>
      <p className={styles.title}>Date</p>
      <p>{ new Date(investment.date_money_received).toLocaleString() }</p>
      <p className={styles.title}>Amount</p>
      <p>{ investment.amount }</p>
      <p className={styles.title} />
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={styles.more}
      >
        <MoreVert />
      </button>
      {
        (isOpen)
        && (
          <InvestmentsManagerDataDropdown
            close={() => setIsOpen(false)}
            investment={investment}
          />
        )
      }
    </div>
  );
}

export default function InvestmentsManagerData({ data, invoicesToExport, handleInvoicesToExport }) {
  return (
    <>
      {
        data.map((investment) => (
          <InvestmentsManagerDataRow
            key={investment.id_transaction}
            investment={investment}
            isChecked={invoicesToExport.includes(investment.invoice)}
            onCheckedChange={handleInvoicesToExport}
          />
        ))
      }
    </>
  );
}
