import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomersManager from './CustomersManager';
import { checkIfLoading } from '../../../../services/store/features/uiAction/selectors';
import * as types from '../../../../services/store/features/customers/types';
import { fetchAllCustomers } from '../../../../services/store/features/customers/actions';

const mapStateToProps = (state) => ({
  status: state.customers.status,
  isLoading: checkIfLoading(state, [types.FETCH_CUSTOMERS]),
  customers: state.customers.customers.list,
  total: state.customers.customers.total,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllCustomers }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersManager);
