import React from 'react';

import stylesInput from '../shared/Input.module.css';
import styles from './TextArea.module.css';

export default function TextArea({
  label, placeholder, hint,
  valueInput, onTextChange,
  name, refs,
}) {
  return (
    <div className={`${styles.container}`}>
      <p
        className={styles.label}
      >
        { label }
      </p>
      <div className={stylesInput.textarea}>
        <textarea
          placeholder={placeholder}
          name={name}
          value={valueInput}
          onChange={onTextChange}
          ref={refs}
        />
      </div>
      <p
        className={styles.hint}
      >
        {hint}
      </p>
    </div>
  );
}
