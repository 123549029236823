export const sortNumberAsc = (a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10);
export const sortNumberDsc = (a, b) => Number.parseInt(b, 10) - Number.parseInt(a, 10);

export const sortAlphabeticallyAsc = (a, b) => {
  if (a < b) { return -1; }
  if (a > b) { return 1; }
  return 0;
};

export const sortAlphabeticallyDsc = (a, b) => {
  if (a > b) { return -1; }
  if (a < b) { return 1; }
  return 0;
};
