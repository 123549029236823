import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DoneAll, Receipt, Visibility } from '@material-ui/icons';

import useOutsideClickAlert from '../../../../../../../components/library/hook/clickOutside';
import styles from './InvoiceManagerDataDropdown.module.css';
import PaymentLink from '../../../../../../../components/PaymentLink';
import InvoiceStatus from '../../../../../../../components/InvoiceStatus';

export default function InvoiceManagerDataDropdown({
  invoice, close, actions, isAdmin = true,
}) {
  const { t } = useTranslation();

  const dropdown = useRef();
  useOutsideClickAlert(dropdown, close);

  const openInvoice = () => {
    actions.setSelectedDocument(invoice);
    close();
  };

  const generatePaymentLink = () => {
    actions.addPopup({
      children: (closePopup) => (
        <PaymentLink
          arrayOfInvoicesId={[invoice.id]}
          close={closePopup}
          id={invoice.project}
          amount={invoice.balance}
        />
      ),
    });
    close();
  };

  const redirectToPayment = () => {
    actions.addPopup({
      children: (closePopup) => (
        <PaymentLink
          arrayOfInvoicesId={[invoice.id]}
          id={invoice.project}
          amount={invoice.balance}
          close={closePopup}
          redirected
        />
      ),
    });
    close();
  };

  const markAsPaid = () => {
    actions.addPopup({
      children: (closePopup) => <InvoiceStatus id={invoice.id} close={closePopup} />,
    });
    close();
  };

  return (
    <div className={styles.dropdown} ref={dropdown}>
      <button
        type="button"
        onClick={openInvoice}
      >
        <Visibility className={styles.icon} />
        {t('invoices.seeInvoice')}
      </button>
      {
        (!isAdmin && (invoice.balance > 0))
        && (
          <button
            type="button"
            onClick={redirectToPayment}
          >
            <Receipt className={styles.icon} />
            {t('invoices.payInvoice')}
          </button>
        )
      }
      {
        (isAdmin && (invoice.balance > 0))
        && (
          <>
            <button
              type="button"
              onClick={generatePaymentLink}
            >
              <Receipt className={styles.icon} />
              Generate a payment link
            </button>
            <button
              type="button"
              onClick={markAsPaid}
            >
              <DoneAll className={styles.icon} />
              Mark as paid
            </button>
          </>
        )
      }
    </div>
  );
}
