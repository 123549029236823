import React, { useRef } from 'react';
import { Visibility } from '@material-ui/icons';
import useOutsideClickAlert from '../../../../../../../components/library/hook/clickOutside';
import styles
  from '../../../../../Invoice/InvoiceManager/InvoiceManagerTable/InvoiceManagerData/InvoiceManagerDataDropdown/InvoiceManagerDataDropdown.module.css';

export default function CreditManagerDataDropdown({ credit, close }) {
  const dropdown = useRef();
  useOutsideClickAlert(dropdown, close);

  const openCredit = () => {
    window.open(credit.url, '_blank');
    close();
  };

  return (
    <div className={styles.dropdown} ref={dropdown}>
      <button
        type="button"
        onClick={openCredit}
      >
        <Visibility className={styles.icon} />
        See the credit
      </button>
    </div>
  );
}
