import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CreditManager from './CreditManager';
import { checkIfLoading } from '../../../../services/store/features/uiAction/selectors';
import * as types from '../../../../services/store/features/credits/types';
import { fetchAllCredits } from '../../../../services/store/features/credits/actions';

const mapStateToProps = (state) => ({
  status: state.credits.status,
  isLoading: checkIfLoading(state, [types.FETCH_CREDITS]),
  credits: state.credits.credits,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllCredits }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditManager);
