// eslint-disable-next-line import/prefer-default-export
export const FETCH_INVESTORS = 'FETCH_INVESTORS';

export const FETCH_REFUND_REQUEST = 'FETCH_REFUND_REQUEST';
export const FETCH_REFUND_SUCCESS = 'FETCH_REFUND_SUCCESS';
export const FETCH_REFUND_FAIL = 'FETCH_REFUND_FAIL';

export const CREATE_REFUND_REQUEST = 'CREATE_REFUND_REQUEST';
export const DELETE_REFUND_SUCCESS = 'DELETE_REFUND_SUCCESS';
export const ACCEPT_REFUND_REQUEST = 'ACCEPT_REFUND_REQUEST';

export const AYOMI_REFUND = 'AYOMI_REFUND';
