import React from 'react';
import { Check } from '@material-ui/icons';

import styles from './Checkbox.module.css';

export default function Checkbox({
  label = '',
  onChange = null,
  disabled = false,
  checked = false,
  className = '',
  name,
}) {
  const handleChange = () => {
    const newValue = !checked;
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <button
        type="button"
        className={
          (disabled) ? `${styles.checkbox} ${styles.disabled}` : (`${styles.checkbox} ${(checked) ? (styles.checked) : (styles.unchecked)}`)
        }
        onClick={handleChange}
        aria-label={name}
      >
        {(checked) && (!disabled) && (
          <Check className={styles.icon} />
        )}
      </button>
      <p className={`${styles.label} ${(disabled) ? styles.labelDisabled : ''}`}>
        {label}
      </p>
    </div>
  );
}
