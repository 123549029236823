import React from 'react';
import {
  Cancel, CheckCircle, Error, Info,
} from '@material-ui/icons';

// eslint-disable-next-line no-unused-vars
let id = 0;

const toastPropertiesByType = (type, color, icon) => {
  const properties = {
    default: {
      icon: <Info />,
      color: '#4F67F3',
    },
    success: {
      icon: <CheckCircle />,
      color: '#00D287',
    },
    warning: {
      icon: <Error />,
      color: '#FBC506',
    },
    error: {
      icon: <Cancel />,
      color: '#F24C27',
    },
    custom: {
      icon,
      color,
    },
  };
  return properties[type];
};

const toastFactory = ({
  type = 'default', title, description, color, icon,
}) => {
  id += 1;
  return {
    ...toastPropertiesByType(type, color, icon),
    id,
    title,
    description,
  };
};

export default toastFactory;
