/* eslint-disable camelcase */
import { isEqual } from 'lodash';
import * as types from './types';
import { startAction, stopAction } from '../uiAction/actions';
import { createNewCredit, getAllInvoices, updateInvoiceAsPaid } from '../../../network/api/invoices';
import { addToast } from '../toasts/actions';
import * as statuses from '../../states';

function formatInvoicesDataByProject(data, project) {
  if (!data.data[project]) return undefined;
  const {
    balance,
    total_amount_discount,
    total_amount_payed,
    total_amount_with_taxes,
    total_amount_without_taxes,
    project_info: projectDetails = {},
    ...invoices
  } = data.data[project];
  const { lead_first_name: firstName = '', lead_last_name: lastName = '' } = projectDetails;
  return {
    project: {
      balance,
      totalPaid: total_amount_payed,
      total: total_amount_with_taxes,
      totalWithoutTaxes: total_amount_without_taxes,
      credits: total_amount_discount,
    },
    invoices: Object.entries(invoices).map(([id, value]) => ({
      ...value,
      id,
      customerName: (firstName && lastName) ? `${firstName} ${lastName}` : 'Unknown',
      uiStatus: statuses.IDLE,
      project,
    })),
    total: data.total,
  };
}

function formatInvoicesData(data) {
  return ({
    ...data,
    invoices: Object.entries(data.data)
      .map(([projectId, value]) => {
        const {
          balance,
          total_amount_discount,
          total_amount_payed,
          total_amount_with_taxes,
          total_amount_without_taxes,
          project_info: projectDetails = {},
          ...invoices
        } = value;
        const { lead_first_name: firstName = '', lead_last_name: lastName = '' } = projectDetails;

        return (
          Object.entries(invoices).map(([invoiceId, invoice]) => ({
            ...invoice,
            ...projectDetails,
            customerName: (firstName && lastName) ? `${firstName} ${lastName}` : 'Unknown',
            id: invoiceId,
            uiStatus: statuses.IDLE,
            project: projectId,
          }))
        );
      })
      .reduce((acc, curr) => [...acc, ...curr], []),
    total: data.total,
  });
}

export function fetchAllInvoices({
  filter, query, page = 0, project = null,
  refreshing = false, maxPerPage = 10,
}) {
  return async (dispatch, getState) => {
    const { previousFetch } = getState().invoices;
    if (isEqual(previousFetch, {
      filter, query, page, project,
    })) return;
    try {
      dispatch(refreshing ? startAction(types.FETCH_INVOICES) : startAction(types.FETCH_INVOICES));
      const { data } = await getAllInvoices(page, filter, query, project, maxPerPage);
      if (project) {
        dispatch({
          type: types.FETCH_INVOICES,
          payload: {
            invoices: formatInvoicesDataByProject(data, project) || { total: 0, invoices: [] },
            fetch: {
              filter, query, page, project,
            },
          },
        });
      } else {
        dispatch({
          type: types.FETCH_INVOICES,
          payload: {
            invoices: formatInvoicesData(data),
            fetch: {
              filter, query, page, project,
            },
          },
        });
      }
    } catch (err) {
      if (window.location.pathname.search('admin') === -1) {
        window.location.assign('/');
      } else {
        dispatch(addToast({
          type: 'error',
          title: 'Invoices can\'t be fetched',
          description: `${err?.response?.data?.action || err}`,
        }));
        dispatch({ type: types.FETCH_INVOICES_FAIL });
      }
    } finally {
      dispatch(stopAction(types.FETCH_INVOICES));
    }
  };
}

export function updateInvoice({
  id, project, reason,
}) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_INVOICE_REQUEST, payload: id });
    try {
      await updateInvoiceAsPaid(id, project, reason);
      dispatch({
        type: types.UPDATE_INVOICE_SUCCESS,
        payload: { id },
      });
      dispatch(addToast({
        type: 'success',
        title: 'Invoice as been updated successfully',
        description: 'Invoice status has been marked as paid',
      }));
    } catch (error) {
      dispatch({ type: types.UPDATE_INVOICE_FAIL, payload: { error, id } });
      dispatch(addToast({
        type: 'error',
        title: 'Invoices can\'t be updated',
        description: `${error?.response?.data?.action || error}`,
      }));
    }
  };
}

export function createCredit({
  selected, paidInvoice, amount, reason = '', project, creditByInvoice,
}) {
  return async (dispatch) => {
    dispatch(startAction(types.CREATE_CREDIT));
    try {
      const { data } = await createNewCredit({
        amount,
        reason,
        id_ayomi: project,
        products_invoices_id: selected.map((invoice) => invoice.id),
        is_percent: false,
      });
      dispatch({
        type: types.CREATE_CREDIT,
        payload: {
          selected: selected.map((invoice) => invoice.id),
          paidInvoice,
          creditByInvoice,
          amount,
          project: data.project,
        },
      });
      dispatch(addToast({
        type: 'success',
        title: 'Credit has been created successfully',
        description: '',
      }));
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Credit creation has failed',
        description: `${err?.response?.data?.action || err}`,
      }));
    } finally {
      dispatch(stopAction(types.CREATE_CREDIT));
    }
  };
}

export function resetInvoice(id) {
  return (dispatch) => dispatch({ type: types.RESET_INVOICE, payload: id });
}

export function setSelectedDocument(url) {
  return (dispatch) => dispatch({ type: types.SET_DOCUMENT, payload: url });
}
