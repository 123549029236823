import React from 'react';
import { exportInvoices } from '../../../../services/network/api/invoices';
import Toolbar from '../../../../components/Toolbar';

export default function InvoiceToolbar({ children }) {
  return (
    <Toolbar getExport={exportInvoices}>
      { children }
    </Toolbar>
  );
}
