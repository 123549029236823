import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as statuses from '../../../../services/store/states';
import Loading from '../../../../components/library/Loading';
import styles from '../../../Admin/Credit/CreditManager/CreditManager.module.css';
import creditsStyles from './CustomerCreditsManager.module.css';
import Pagination from '../../../../components/Pagination';

export default function CustomerCreditsManager({
  status, data, children, actions,
}) {
  const [page, setPage] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    actions.fetchAllCredits({ page, project: id });
  }, [page, id]);

  if (status === statuses.IDLE) return <Loading />;

  if (status === statuses.FAIL || data.credits.length === 0) {
    return (
      <div className={styles.error}>
        <p> No credits available </p>
      </div>
    );
  }

  return (
    <>
      { children(data.credits) }
      <Pagination
        currentPage={page}
        total={data.total}
        setPage={setPage}
        className={creditsStyles.pagination}
      />
    </>
  );
}
