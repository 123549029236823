import React from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../../../../components/library/Button/Button';
import styles from './InvoiceSumup.module.css';
import PaymentLink from '../../../../../components/PaymentLink';

export default function InvoiceSumup({
  project: {
    total = 0, totalPaid = 0, credits = 0,
    balance = 0, totalWithoutTaxes = 0,
  } = {}, invoicesId, isLoading, actions,
}) {
  const { id } = useParams();

  if (isLoading) return null;
  return (
    <div className={styles.sumup}>
      <div className={styles.row}>
        <p className={styles.label}>Total</p>
        <p className={styles.hide}>{ totalWithoutTaxes.toLocaleString('fr-FR') }</p>
        <p>{ total.toLocaleString('fr-FR') }</p>
        <p className={styles.hide} />
        <p className={styles.label}>Amount already paid</p>
        <p className={styles.hide} />
        <p>{ totalPaid.toLocaleString('fr-FR')}</p>
        <p className={styles.hide} />
        <p className={styles.label}>Credit amount</p>
        <p>{ credits.toLocaleString('fr-FR') }</p>
        <p className={styles.hide} />
      </div>
      <div className={styles.result}>
        <div className={styles.row}>
          <p className={styles.label}>Balance</p>
          <p className={styles.hide} />
          <p className={(balance > 0) ? styles.balance : ''}>{ balance.toLocaleString('fr-FR') }</p>
          <Button
            label="Create payment link"
            size="small"
            className={styles.hide}
            disabled={balance === 0}
            onButtonClick={() => actions.addPopup({
              children: (closePopup) => (
                <PaymentLink
                  arrayOfInvoicesId={invoicesId}
                  id={id}
                  amount={balance}
                  close={closePopup}
                />
              ),
            })}
          />
        </div>
        <Button
          label="Create payment link"
          size="small"
          className={styles.button}
          disabled={balance === 0}
          onButtonClick={() => actions.addPopup({
            children: (closePopup) => (
              <PaymentLink
                arrayOfInvoicesId={invoicesId}
                id={id}
                amount={balance}
                close={closePopup}
              />
            ),
          })}
        />
      </div>
    </div>
  );
}
