import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Warning } from '@material-ui/icons';

import Button from '../../../../components/library/Button/Button';
import styles from './CustomerRefundData.module.css';
import { acceptRefundRequest, deleteRefundRequest } from '../../../../services/store/features/investors/actions';

const CustomerRefundDataRow = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: project } = useParams();

  const handleDeleteRequest = () => {
    dispatch(deleteRefundRequest(item.id_stripe, project, t));
  };

  const handleAcceptRequest = () => {
    if (isOpen) {
      dispatch(acceptRefundRequest(item.id_stripe, project, t));
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const cancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {
        (isOpen)
        && (
          <section className={styles.background}>
            <div className={styles.modal}>
              <div className={styles.warning}>
                <Warning className={styles.icon} />
              </div>
              <p className={styles.text}>
                {t('refund.modal.refund1')}
                <b>
                  {t('refund.modal.refundBold')}
                </b>
                {t('refund.modal.refund2')}
              </p>
              <div className={styles.footer}>
                <Button
                  label={t('refund.modal.cancel')}
                  onButtonClick={cancel}
                  variant="ghost"
                />
                <Button
                  label={t('refund.modal.next')}
                  onButtonClick={handleAcceptRequest}
                />
              </div>
            </div>
          </section>
        )
      }
      <div className={styles.refundRow}>
        <p className={styles.hideOverflow}>{item?.email}</p>
        <p>{item?.firstname}</p>
        <p>{item?.lastname}</p>
        <p>{item?.amount}</p>
        <div className={styles.row}>
          <Button
            label={t('refund.accept')}
            size="small"
            className={styles.button}
            onButtonClick={handleAcceptRequest}
          />
          <Button
            label={t('refund.refuse')}
            size="small"
            type="primary"
            className={styles.button}
            onButtonClick={handleDeleteRequest}
          />
        </div>
      </div>
    </>
  );
};

export default function CustomerRefundData({ data = [] }) {
  const { t } = useTranslation();

  if (data && (data.length === 0)) {
    return (
      <p className={styles.empty}>
        {t('refund.empty')}
      </p>
    );
  }

  return (
    <>
      {
        data && data.map && data.map((item) => (
          <CustomerRefundDataRow
            key={item.id_stripe}
            item={item}
          />
        ))
      }
    </>
  );
}
