import * as statuses from '../../states';
import * as types from './types';

const initialState = {
  status: statuses.IDLE,
  investors: [],
  refunds: {
    status: statuses.IDLE,
    data: [],
  },
};

export default function investorsReducer(state = initialState, { type, payload }) {
  const { [payload]: value, ...newRefunds } = state.refunds.data;
  switch (type) {
    case types.FETCH_INVESTORS:
      return {
        ...state,
        status: statuses.SUCCESS,
        investors: payload,
      };
    case types.FETCH_REFUND_REQUEST:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          status: statuses.LOADING,
        },
      };
    case types.FETCH_REFUND_SUCCESS:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          status: statuses.SUCCESS,
          data: payload,
        },
      };
    case types.FETCH_REFUND_FAIL:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          status: statuses.FAIL,
          error: payload,
        },
      };
    case types.CREATE_REFUND_REQUEST:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          data: {
            ...state.refunds.data,
            [payload.id]: payload,
          },
        },
      };
    case types.DELETE_REFUND_SUCCESS:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          data: newRefunds,
        },
      };
    case types.ACCEPT_REFUND_REQUEST:
      return {
        ...state,
        refunds: {
          ...state.refunds,
          data: newRefunds,
        },
      };
    case types.AYOMI_REFUND:
      return {
        ...state,
        investors: state.investors.map((invest) => (
          (invest.id_stripe === payload)
            ? { ...invest, status: 'refunded' }
            : invest
        )),
      };
    default:
      return state;
  }
}
