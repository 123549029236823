import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  status: statuses.IDLE,
  selectedDocument: null,
  previousFetch: {},
  invoices: {
    total: 0,
    invoices: [],
  },
};

export default function invoicesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_INVOICES:
      return {
        ...state,
        invoices: { ...state.invoices, ...payload.invoices },
        status: statuses.SUCCESS,
        previousFetch: payload.fetch,
      };
    case types.FETCH_INVOICES_FAIL:
      return { ...state, status: statuses.FAIL };
    case types.UPDATE_INVOICE_REQUEST:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          invoices: state.invoices.invoices.map((invoice) => ((invoice.id === payload)
            ? { ...invoice, uiStatus: statuses.LOADING }
            : invoice)),
        },
      };
    case types.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          project: {
            ...state.invoices.project,
            balance: (state?.invoices?.project?.balance)
              && state.invoices.project.balance - state.invoices.invoices
                .find((invoice) => invoice.id === payload.id).balance,
          },
          invoices: state.invoices.invoices.map((invoice) => ((invoice.id === payload.id)
            ? {
              ...invoice,
              ...payload,
              uiStatus: statuses.SUCCESS,
              balance: 0,
            }
            : invoice)),
        },
      };
    case types.UPDATE_INVOICE_FAIL:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          invoices: state.invoices.invoices.map((invoice) => ((invoice.id === payload.id)
            ? { ...invoice, uiStatus: statuses.FAIL, error: payload.error }
            : invoice)),
        },
      };
    case types.RESET_INVOICE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          invoices: state.invoices.invoices.map((invoice) => ((invoice.id === payload)
            ? { ...invoice, uiStatus: statuses.IDLE }
            : invoice)),
        },
      };
    case types.CREATE_CREDIT:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          project: payload.project,
          invoices: state.invoices.invoices
            .map((invoice) => (payload.selected.includes(invoice.id)
              ? {
                ...invoice,
                discount_amount: payload.creditByInvoice[payload.selected.indexOf(invoice.id)],
                balance: (invoice.amountHT === payload.amount) ? 0 : 'N/A',
              }
              : invoice
            )),
        },
      };
    case types.SET_DOCUMENT:
      return { ...state, selectedDocument: payload };
    default:
      return state;
  }
}
