import React from 'react';
import Table from '../../../../../components/Table';
import CreditManagerData from './CreditManagerData';
import styles from './CreditManagerTable.module.css';

export default function CreditManagerTable({ data, creditsToExport, handleCreditsToExport }) {
  return (
    <section className={styles.table}>
      <Table
        data={data}
        dataToExport={creditsToExport}
        handleDataToExport={handleCreditsToExport}
        columns={[
          { label: 'Customer', value: 'customerName', type: 'alpha' },
          { label: 'Credit ID', value: 'id', type: 'alpha' },
          { label: 'Invoices associated', value: 'invoices', type: 'array' },
          { label: 'Amount excl tax', value: 'amount', type: 'num' },
          { label: 'Date', value: 'date', type: 'num' },
        ]}
      >
        {
          (currentData) => (
            <CreditManagerData
              data={currentData}
              creditsToExport={creditsToExport}
              handleCreditsToExport={handleCreditsToExport}
            />
          )
        }
      </Table>
    </section>
  );
}
