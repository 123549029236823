import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PopupManager from './PopupManager';
import { addPopup, removePopup } from '../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({ popups: state.popups.list });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    addPopup, removePopup,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupManager);
