import * as types from './types';

export function startAction(name, params) {
  return (dispatch) => dispatch({
    type: types.START_ACTION,
    payload: {
      action: { name, params },
    },
  });
}

export function stopAction(name, params) {
  return (dispatch) => dispatch({
    type: types.STOP_ACTION,
    payload: {
      action: { name, params },
    },
  });
}
