import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@material-ui/icons';

import Toolbar from '../../../components/Toolbar';
import styles from './CustomerPayment.module.css';
import CustomerPaymentManager from './CustomerPaymentManager';
import CustomerInvoiceData from '../CustomerInvoice/CustomerInvoiceData';
import Table from '../../../components/Table';
import Button from '../../../components/library/Button/Button';
import PaymentLink from '../../../components/PaymentLink';

export default function CustomerPayment({ invoices, actions }) {
  const { t } = useTranslation();
  const { id: projectId } = useParams();

  const paySelection = (invoicesId) => (
    <Button
      label={t('invoices.paySelection')}
      icon={<AddCircle />}
      onButtonClick={() => actions.addPopup({
        children: (closePopup) => (
          <PaymentLink
            arrayOfInvoicesId={invoicesId}
            id={projectId}
            amount={invoices
              ?.filter((invoice) => invoicesId.includes(invoice.id))
              ?.reduce((acc, curr) => acc + curr.balance, 0) || 0}
            close={closePopup}
            redirected
          />
        ),
      })}
      disabled={invoices.length === 0}
    />
  );

  const getCurrentData = (queryToSearch, data) => data && data
    .filter(({
      id, amountTTC, amountHT, discount_amount: credits, project,
    }) => {
      const itemToString = `${id} ${amountTTC} ${amountHT} ${credits} ${project}`.toLowerCase();
      return itemToString.search(queryToSearch.toLowerCase()) !== -1;
    });

  return (
    <Toolbar
      options={paySelection}
      className={styles.toolbar}
    >
      {
        (query, idToExport, handleInvoicesToExport) => (
          <CustomerPaymentManager>
            {
              (data) => ((data.invoices.length === 0)
                ? (
                  <div className={styles.error}>
                    <p> No invoices to display </p>
                  </div>
                )
                : (
                  <div className={styles.table}>
                    <Table
                      data={data.invoices}
                      handleDataToExport={handleInvoicesToExport}
                      dataToExport={idToExport}
                      columns={[
                        { label: t('invoices.reference'), value: 'id', type: 'alpha' },
                        { label: t('invoices.date'), value: 'date', type: 'num' },
                        { label: t('invoices.amountHT'), value: 'amountHT', type: 'num' },
                        { label: t('invoices.amountTTC'), value: 'amountTTC', type: 'num' },
                        { label: t('invoices.credits'), value: 'discount_amount', type: 'num' },
                        { label: t('invoices.balance'), value: 'balance', type: 'num' },
                        { label: t('invoices.status'), value: 'status', type: 'alpha' },
                      ]}
                    >
                      {
                      (currentData) => (
                        <CustomerInvoiceData
                          data={getCurrentData(query, currentData)}
                          handleDataToExport={handleInvoicesToExport}
                          dataToExport={idToExport}
                          actions={actions}
                        />
                      )
                    }
                    </Table>
                  </div>
                ))
            }
          </CustomerPaymentManager>
        )
      }
    </Toolbar>
  );
}
