import React, { useEffect, useState } from 'react';
import Button from '../library/Button/Button';
import InputText from '../library/Input/InputText/InputText';
import styles from './CreditCreation.module.css';
import TextArea from '../library/Input/TextArea/TextArea';

export default function CreditCreation({
  project, invoices, selected, close, amount = 0, actions,
}) {
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [balance, setBalance] = useState([]);
  const [credit, setCredit] = useState(amount);
  const [gain, setGain] = useState(0);
  const [reason, setReason] = useState('');
  const [max, setMax] = useState(0);

  useEffect(() => {
    if (invoices) {
      const selections = invoices.filter((invoice) => selected.includes(invoice.id) && invoice.status !== 'paid');
      const maxValue = selections.reduce((acc, curr) => acc + curr.amountHT, 0);
      selections.sort((a, b) => a.amountHT - b.amountHT);
      setSelectedInvoices(selections);
      setBalance(selections.map((invoice) => invoice.amountHT));
      setCredit(maxValue);
      setMax(maxValue);
    }
  }, []);

  useEffect(() => {
    if (balance.length > 0) {
      let base = selectedInvoices.map((invoice) => invoice.amountHT);
      setGain(0);
      let available = credit;
      if (available > 0) {
        base = base.map((invoice) => {
          if (available > invoice) {
            available -= invoice;
            return 0;
          }
          const result = invoice - available;
          available = 0;
          return result;
        });
      }
      setBalance(base);
      if (available > 0) setGain(available);
    }
  }, [credit]);

  const getCreditByInvoice = () => {
    const creditByInvoice = [];
    let creditAmountLeft = credit;
    selectedInvoices.forEach((invoice) => {
      if (invoice.amountHT < creditAmountLeft) {
        creditByInvoice.push(invoice.amountHT);
        creditAmountLeft -= invoice.amountHT;
      } else {
        creditByInvoice.push(creditAmountLeft);
        creditAmountLeft = 0;
      }
    });
    return creditByInvoice;
  };

  const submit = () => {
    actions.createCredit({
      selected: selectedInvoices,
      paidInvoice: selectedInvoices
        .filter((invoice, i) => balance[i] === 0)
        .map((invoice) => invoice.id),
      amount: credit,
      creditByInvoice: getCreditByInvoice(),
      reason,
      project,
    });
    close();
  };

  return (
    <>
      <header>
        <h1>
          Create a credit
        </h1>
        <p>
          Create a credit linked to one or several invoices selected.
        </p>
      </header>
      <InputText
        label="Credit amount"
        type="number"
        max={selectedInvoices.reduce((acc, curr) => acc + curr.amountHT, 0)}
        valueInput={credit}
        onTextChange={(e) => setCredit(Number.parseFloat(e.target.value))}
      />
      {
        (credit > max)
        && (
          <p className={styles.error}>
            L&apos;avoir ne peut pas être supérieur au montant des factures.
          </p>
        )
      }
      <TextArea
        label="Reason"
        valueInput={reason}
        onTextChange={(e) => setReason(e.target.value)}
      />
      {
        (selectedInvoices.length > 0)
        && (
          <>
            <p className={styles.label}>
              Selected invoices
            </p>
            {
              selectedInvoices.map((invoice, i) => (
                <p key={invoice.id}>
                  {`${invoice.id} - ${invoice.amountHT.toLocaleString('fr-FR')}€`}
                  {
                    ((balance[i]) === 0) && <span className={`bounceIn ${styles.success}`}>PAID</span>
                  }
                </p>
              ))
            }
            <p className={styles.label}>
              Balance :
              <span className={styles.balance}>
                {`${(balance.reduce((acc, curr) => acc + curr, 0) - gain).toLocaleString('fr-FR')}€`}
              </span>
            </p>
          </>
        )
      }
      <Button
        label="Create a credit"
        onButtonClick={submit}
        disabled={reason === '' || credit === 0 || Number.isNaN(credit) || (credit > max)}
      />
      <Button
        label="Cancel"
        variant="ghost"
        className={styles.cancel}
        onButtonClick={close}
      />
    </>
  );
}
