import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/library/Button/Button';
import styles from './CustomerLayout.module.css';
import PaymentLink from '../../../components/PaymentLink';
import * as statuses from '../../../services/store/states';

export default function CustomerLayout({
  balance = 0, status, invoices, children, actions,
}) {
  const [tabToDisplay, setTabToDisplay] = useState('invoices');
  const { t } = useTranslation();
  const { id } = useParams();

  const setActiveTab = (e) => setTabToDisplay(e.target.name);

  const paySelection = () => (
    actions.addPopup({
      children: (closePopup) => (
        <PaymentLink
          arrayOfInvoicesId={invoices}
          amount={balance}
          id={id}
          close={closePopup}
          redirected
        />
      ),
    })
  );

  return (
    <>
      <section
        data-testid="customer-layout"
        className={(status !== statuses.SUCCESS) ? styles.hide : 'fadeIn'}
      >
        <header className={styles.header}>
          <div className={styles.content}>
            <a className={styles.logo} href="https://ayomi.fr">AYOMI</a>
            <h1>{t('invoices.title')}</h1>
            {
              (balance > 0)
              && (
                <div className={styles.balance}>
                  <p className={styles.title}>{t('invoices.balance')}</p>
                  <div className={styles.row}>
                    <p className={styles.number}>
                      {`${balance} €`}
                    </p>
                    <Button
                      type="custom"
                      label={t('invoices.pay')}
                      size="small"
                      className={styles.custom}
                      onButtonClick={paySelection}
                    />
                  </div>
                </div>
              )
            }
            <nav className={styles.navigation}>
              <button
                type="button"
                name="invoices"
                className={(tabToDisplay === 'invoices') ? styles.active : ''}
                onClick={setActiveTab}
              >
                {t('invoices.navigation.invoice')}
              </button>
              <button
                type="button"
                name="payments"
                className={(tabToDisplay === 'payments') ? styles.active : ''}
                onClick={setActiveTab}
              >
                {t('invoices.navigation.payment')}
              </button>
              <button
                type="button"
                name="credits"
                className={(tabToDisplay === 'credits') ? styles.active : ''}
                onClick={setActiveTab}
              >
                {t('invoices.navigation.credits')}
              </button>
              <button
                type="button"
                name="refund"
                className={(tabToDisplay === 'refund') ? styles.active : ''}
                onClick={setActiveTab}
              >
                {t('invoices.navigation.refund')}
              </button>
            </nav>
          </div>
        </header>
        <main className={styles.content}>
          { children(tabToDisplay) }
        </main>
      </section>
    </>
  );
}
