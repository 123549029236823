import React from 'react';
import RouteConfig from './routes/routes';

import './components/library/style/normalize.css';
import './components/library/style/reset.css';
import './components/library/style/typography.css';
import './components/library/style/animation.css';
import Toasts from './components/library/Toasts';
import PopupManager from './components/PopupManager';
import MediaViewer from './views/MediaViewer';

function App() {
  return (
    <div className="App">
      <RouteConfig />
      <Toasts />
      <PopupManager />
      <MediaViewer />
    </div>
  );
}

export default App;
