import { isEqual } from 'lodash';
import * as types from './types';

const initialState = {
  loader: {
    actions: [],
  },
};

export default function uiReducer(state = initialState, { type, payload }) {
  const { loader } = state;
  const { actions } = loader;
  switch (type) {
    case types.START_ACTION:
      return { ...state, loader: { ...loader, actions: [...actions, payload.action] } };
    case types.STOP_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter((action) => {
            if (action.name !== payload.action.name) {
              return true;
            } if (!isEqual(action.params, payload.action.params)) {
              return true;
            }
            return false;
          }),
        },
      };
    default:
      return state;
  }
}
