import React from 'react';
import { AddCircle } from '@material-ui/icons';
import { useParams } from 'react-router-dom';

import Toolbar from '../../../../components/Toolbar';
import Button from '../../../../components/library/Button/Button';
import InvoiceManager from '../../Invoice/InvoiceManager';
import InvoiceSumup from './InvoiceSumup';
import CreditCreation from '../../../../components/CreditCreation';
import { exportInvoices } from '../../../../services/network/api/invoices';

export default function Invoice({ status, actions }) {
  const { id } = useParams();

  const createCreditButton = (invoices) => (
    <Button
      label="Create credit"
      icon={<AddCircle />}
      onButtonClick={() => actions.addPopup({
        children: (closePopup) => (
          <CreditCreation
            project={id}
            selected={invoices}
            close={closePopup}
          />
        ),
      })}
      disabled={invoices.length === 0}
    />
  );

  return (
    <section>
      <Toolbar
        options={createCreditButton}
        getExport={exportInvoices}
      >
        {
          (query, invoices, handleExport) => (
            <>
              <InvoiceManager
                project={id}
                query={query}
                filter={status}
                invoicesToExport={invoices}
                handleInvoicesToExport={handleExport}
                showPagination={false}
                maxPerPage={(status === 'wait') ? 50 : 10}
              />
              {
                (status === 'wait')
                && <InvoiceSumup />
              }
            </>
          )
        }
      </Toolbar>
    </section>
  );
}
