import React from 'react';
import { useTranslation } from 'react-i18next';

import Toolbar from '../../../components/Toolbar';
import Table from '../../../components/Table';
import styles from './CustomerCredits.module.css';
import CustomerCreditsManager from './CustomerCreditsManager';
import CreditManagerData from '../../Admin/Credit/CreditManager/CreditManagerTable/CreditManagerData/CreditManagerData';

export default function CustomerCredits() {
  const { t } = useTranslation();

  const getCurrentData = (queryToSearch, data) => data
    .filter(({ id, invoices, discount_amount: credits }) => {
      const itemToString = `${id} ${invoices?.join(' ')} ${credits}`.toLowerCase();
      return itemToString.search(queryToSearch.toLowerCase()) !== -1;
    });

  return (
    <Toolbar
      className={styles.toolbar}
    >
      {
        (query, idToExport, handleCreditsToExport) => (
          <CustomerCreditsManager>
            {
              (data) => (
                <div className={styles.table}>
                  <Table
                    data={data}
                    handleDataToExport={handleCreditsToExport}
                    dataToExport={idToExport}
                    columns={[
                      { label: t('invoices.reference'), value: 'id', type: 'alpha' },
                      { label: t('invoices.invoice'), value: 'invoice', type: 'alpha' },
                      { label: t('invoices.credits'), value: 'discount_amount', type: 'num' },
                      { label: t('invoices.date'), value: 'date', type: 'num' },
                    ]}
                  >
                    {
                      (currentData) => (
                        <CreditManagerData
                          data={getCurrentData(query, currentData)}
                          handleCreditsToExport={handleCreditsToExport}
                          creditsToExport={idToExport}
                          hideCustomer
                          hideOption
                        />
                      )
                    }
                  </Table>
                </div>
              )
            }
          </CustomerCreditsManager>
        )
      }
    </Toolbar>
  );
}
