import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaViewer from './MediaViewer';
import { setSelectedDocument } from '../../services/store/features/invoices/actions';

const mapStateToProps = (state) => ({
  selectedDocument: state.invoices.selectedDocument,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setSelectedDocument }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaViewer);
