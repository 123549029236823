import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../components/library/Button/Button';
import styles from './Error.module.css';

export default function Error() {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <section className={`fadeIn ${styles.error}`}>
      <h1>404</h1>
      <p className={styles.subtitle}>
        {t('error.title')}
      </p>
      <p className={styles.description}>
        {t('error.description')}
      </p>
      <div className={styles.row}>
        <Button
          label={t('error.back')}
          onButtonClick={() => history.go(-2)}
        />
        <Button
          label={t('error.return')}
          onButtonClick={() => window.location.assign('https://ayomi.fr')}
        />
      </div>
    </section>
  );
}
