import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@material-ui/icons';
import Checkbox from '../../../../components/library/Input/Checkbox/Checkbox';
import InvoiceManagerDataDropdown
  from '../../../Admin/Invoice/InvoiceManager/InvoiceManagerTable/InvoiceManagerData/InvoiceManagerDataDropdown';
import styles from './CustomerInvoiceData.module.css';

const CustomerInvoiceDataRow = ({
  item, handleDataToExport, checked, actions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className={styles.row}
    >
      <Checkbox
        checked={checked}
        name={`checkbox-${item.id}`}
        onChange={() => handleDataToExport([item.id])}
      />
      <p className={styles.title} />
      <p className={styles.reference}>{item.id}</p>
      <p className={styles.title} />
      <p className={styles.title}>Date</p>
      <p>{new Date(item.issue_date).toLocaleDateString()}</p>
      <p className={styles.title}>{t('invoices.amountHT')}</p>
      <p>{item.amountHT}</p>
      <p className={styles.title}>{t('invoices.amountTTC')}</p>
      <p>{item.amountTTC}</p>
      <p>{item.discount_amount}</p>
      <p className={styles.title}>{t('invoices.discount_amount')}</p>
      {
        (item.balance !== 0)
        && (
          <>
            <p>{item.balance}</p>
            <p className={styles.title}>{t('invoices.balance')}</p>
          </>
        )

      }
      {
        (item.balance > 0)
          ? <p className={`${styles.status} ${styles.wait}`}>{t('invoices.wait')}</p>
          : <p className={`${styles.status} ${styles.paid}`}>{t('invoices.paid')}</p>
      }
      <p className={styles.title} />
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={styles.more}
        aria-label={`options-${item.id}`}
      >
        <MoreHoriz />
      </button>
      {
        (isOpen)
        && (
          <InvoiceManagerDataDropdown
            isAdmin={false}
            close={() => setIsOpen(false)}
            invoice={item}
            actions={actions}
          />
        )
      }
    </div>
  );
};

export default function CustomerInvoiceData({
  data, dataToExport, handleDataToExport, actions,
}) {
  return (
    <>
      {
        data && data.map((item) => (
          <CustomerInvoiceDataRow
            key={item.id}
            item={item}
            actions={actions}
            checked={dataToExport?.includes(item.id)}
            handleDataToExport={handleDataToExport}
          />
        ))
      }
    </>
  );
}
