import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';

export default function CustomerPaymentManager({
  actions, filter = 'wait', data, children,
}) {
  const [page, setPage] = useState('1');
  const { id } = useParams();

  useEffect(() => {
    actions.fetchAllInvoices({ project: id, filter, page });
  }, [page]);

  return (
    <>
      {children(data)}
      <Pagination
        total={data?.total || 0}
        currentPage={page}
        setPage={setPage}
      />
    </>
  );
}
