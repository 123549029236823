import React from 'react';
import {
  sortAlphabeticallyAsc, sortAlphabeticallyDsc, sortNumberAsc, sortNumberDsc,
} from '../../utils/sort';
import TableHeader from './TableHeader';

export default function Table({
  data, dataToExport, handleDataToExport, columns, children, exportColumn = 'id',
}) {
  const transformInvoices = (invoices) => (invoices.map((invoice) => ({
    ...invoice,
    ...Object.assign(...columns.map((column) => ({
      [column.value]: (column.transform)
        ? column.transform(invoice)
        : invoice[column.value],
    }))),
  })));

  const getCurrentInvoicesData = (category, order) => {
    let invoices = transformInvoices(data);
    if (category) {
      if (columns.filter((column) => column.type === 'alpha').map((column) => column.value).includes(category)) {
        invoices = (order === 'asc')
          ? invoices.sort((a, b) => sortAlphabeticallyAsc(a[category], b[category]))
          : invoices.sort((a, b) => sortAlphabeticallyDsc(a[category], b[category]));
      } else if (columns.filter((column) => column.type === 'num').map((column) => column.value).includes(category)) {
        invoices = (order === 'asc')
          ? invoices.sort((a, b) => sortNumberAsc(a[category], b[category]))
          : invoices.sort((a, b) => sortNumberDsc(a[category], b[category]));
      }
    }
    return invoices;
  };

  const handleExport = (bool) => {
    if (bool) {
      handleDataToExport(data.map((invoice) => invoice[exportColumn]));
    } else {
      handleDataToExport([]);
    }
  };

  return (
    <TableHeader
      isChecked={data?.length === dataToExport?.length}
      handleExport={(handleDataToExport) ? handleExport : null}
      columns={columns}
    >
      {
          (category, order) => children(getCurrentInvoicesData(category, order))
      }
    </TableHeader>
  );
}
