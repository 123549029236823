import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvoiceManager from './InvoiceManager';
import { checkIfLoading } from '../../../../services/store/features/uiAction/selectors';
import * as types from '../../../../services/store/features/invoices/types';
import { fetchAllInvoices } from '../../../../services/store/features/invoices/actions';

const mapStateToProps = (state) => ({
  status: state.invoices.status,
  isLoading: checkIfLoading(state, [types.FETCH_INVOICES]),
  invoices: state.invoices.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllInvoices }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceManager);
