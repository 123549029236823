import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvestmentsManager from './InvestmentsManager';
import { fetchAllInvestors } from '../../../../../services/store/features/investors/actions';
import { checkIfLoading } from '../../../../../services/store/features/uiAction/selectors';
import * as types from '../../../../../services/store/features/investors/types';

const mapStateToProps = (state) => ({
  isLoading: checkIfLoading(state, [types.FETCH_INVESTORS]),
  data: state.investors.investors || [],
  refunds: state.investors.refunds.data,
  status: state.investors.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllInvestors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentsManager);
