import { isEqual } from 'lodash';
import * as types from './types';
import { startAction, stopAction } from '../uiAction/actions';
import { addToast } from '../toasts/actions';
import { getAllCustomers } from '../../../network/api/invoices';

function formatProject(project) {
  const [id, value] = Object.entries(project)[0];
  return {
    fullname: `${value.lead_first_name} ${value.lead_last_name}`,
    email: value.email,
    name: value.project_name,
    id,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function fetchAllCustomers(query, page) {
  return async (dispatch, getState) => {
    const previousFetch = getState().customers;
    if (isEqual(previousFetch, { query, page })) return;
    dispatch(startAction(types.FETCH_CUSTOMERS));
    try {
      const { data } = await getAllCustomers(page, query);
      dispatch({
        type: types.FETCH_CUSTOMERS,
        payload: {
          customers: data.projects.map((project) => formatProject(project)),
          total: data.total,
          fetch: { query, page },
        },
      });
    } catch (error) {
      dispatch(addToast({
        type: 'error',
        title: 'Customers can\'t be fetched',
        description: `A technical error occured: ${error}`,
      }));
      dispatch({ type: types.FETCH_CUSTOMERS_FAILED, payload: error });
    } finally {
      dispatch(stopAction(types.FETCH_CUSTOMERS));
    }
  };
}
