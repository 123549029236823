import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import Invoice from './Invoice';
import Credits from './Credits';
import Investments from './Investments';

export default function CustomerInvoice() {
  const { id } = useParams();

  const getCustomerTab = (value = 'default') => {
    const CUSTOMER_TAB = {
      credits: <Credits status="wait" />,
      'credits-paid': <Credits status="paid" />,
      investments: <Investments />,
      'invoices-paid': <Invoice status="paid" />,
      default: <Invoice status="wait" />,
    };
    return CUSTOMER_TAB[value || 'default'];
  };

  return (
    <Layout
      title={`Project ${id}`}
      buttons={[
        { label: 'Wait', value: null },
        { label: 'Paid', value: 'invoices-paid' },
        { label: 'Credits', value: 'credits' },
        // { label: 'Credits Paid', value: 'credits-paid' },
        { label: 'Investments', value: 'investments' },
      ]}
      hasBackButton
    >
      { (value) => getCustomerTab(value) }
    </Layout>
  );
}
