import React, { useState } from 'react';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import Checkbox from '../../library/Input/Checkbox/Checkbox';
import styles from './TableHeader.module.css';

export default function TableHeader({
  children, isChecked, handleExport, columns, hasOptions = true,
}) {
  const [category, setCategory] = useState(null);
  const [order, setOrder] = useState('asc');

  const handleButton = (e) => {
    if (category === e.target.name) {
      setOrder((state) => (state === 'asc' ? 'dsc' : 'asc'));
    } else {
      setCategory(e.target.name);
    }
  };
  return (
    <>
      <div className={styles.header}>
        {
          handleExport
          && (
            <Checkbox
              checked={isChecked}
              onChange={handleExport}
            />
          )
        }
        {
          (columns.map((column) => (
            <button
              key={column.value}
              type="button"
              name={column.value}
              onClick={handleButton}
            >
              {column.label}
              {
                (category === column.value)
                && (
                  (order === 'dsc')
                    ? <ArrowDownward className={styles.icon} />
                    : <ArrowUpward className={styles.icon} />
                )
              }
            </button>
          )))
        }
        {
          (hasOptions)
          && <div />
        }
      </div>
      { children(category, order)}
    </>
  );
}
