import * as types from './types';
import { startAction, stopAction } from '../uiAction/actions';
import {
  getAllInvestorsById, getAllRefundRequest, refund, sendRefundRequest,
} from '../../../network/api/invoices';
import { addToast } from '../toasts/actions';

// eslint-disable-next-line import/prefer-default-export
export function fetchAllInvestors(id) {
  return async (dispatch) => {
    dispatch(startAction(types.FETCH_INVESTORS));
    try {
      const { data } = await getAllInvestorsById(id);
      if (data.status === 'succeed') {
        dispatch({
          type: types.FETCH_INVESTORS,
          payload: data.investment,
        });
        dispatch({ type: types.FETCH_REFUND_SUCCESS, payload: data.refund_request });
      }
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Impossible to get the investors list',
        description: `A technical error occurred : ${err.response?.data?.actions || err}`,
      }));
    } finally {
      dispatch(stopAction(types.FETCH_INVESTORS));
    }
  };
}

export function getRefundRequest() {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_REFUND_REQUEST });
    try {
      const { data } = await getAllRefundRequest();
      dispatch({ type: types.FETCH_REFUND_SUCCESS, payload: data.data });
    } catch (err) {
      dispatch({ type: types.FETCH_REFUND_FAIL, payload: err });
      dispatch(addToast({
        type: 'error',
        title: 'Impossible to get refunds request',
        description: `A technical error occurred : ${err?.response?.data?.actions || err}`,
      }));
    }
  };
}

export function deleteRefundRequest(id, project, t) {
  return async (dispatch) => {
    try {
      await sendRefundRequest({
        investment_id: id,
        id_ayomi: project,
        action: 'delete',
        is_ayomi: false,
      });
      dispatch({ type: types.DELETE_REFUND_SUCCESS, payload: id });
      dispatch(addToast({
        type: 'success',
        title: t('refund.requestDeleted.title'),
        description: t('refund.requestDeleted.description'),
      }));
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: t('refund.requestDeleted.error.title'),
        description: t('refund.requestDeleted.error.description'),
      }));
    }
  };
}

export function acceptRefundRequest(id, project, t) {
  return async (dispatch) => {
    try {
      await refund({
        investment_id: id,
        action: 'validate',
        is_ayomi: false,
        id_ayomi: project,
      });
      dispatch({ type: types.ACCEPT_REFUND_REQUEST, payload: id });
      dispatch(addToast({
        type: 'success',
        title: t('refund.requestAccepted.title'),
        description: t('refund.requestAccepted.description'),
      }));
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: t('refund.requestAccepted.error.title'),
        description: t('refund.requestAccepted.error.description'),
      }));
    }
  };
}

export function ayomiRefund(investment, project, statusCallback, errorCallback, close) {
  return async (dispatch) => {
    statusCallback('LOADING');
    try {
      const { data } = await refund({
        investment_id: investment,
        id_ayomi: project,
        is_ayomi: true,
        action: 'validate',
      });
      if (data.status === 'succeed') {
        statusCallback('SUCCESS');
        dispatch(addToast({
          type: 'success',
          title: 'Refund has been approved',
          description: 'Your refund has been done successfully',
        }));
        dispatch({ type: types.AYOMI_REFUND, payload: investment });
        close();
      } else {
        statusCallback('ERROR');
        errorCallback((data.status === 'KO') ? data.msg : data.action);
      }
    } catch (err) {
      statusCallback('ERROR');
      errorCallback(`${err}`);
    }
  };
}
