import * as types from './types';

const initialState = {
  list: [],
  autoDeleteTime: 10000,
};

export default function toastsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.ADD_TOAST:
      return { ...state, list: [...state.list, payload] };
    case types.REMOVE_TOAST:
      return { ...state, list: state.list.filter((item) => item.id !== payload) };
    default:
      return state;
  }
}
