import React from 'react';
import { useParams } from 'react-router-dom';
import { exportCredits } from '../../../../services/network/api/invoices';
import CreditManager from '../../Credit/CreditManager';
import Toolbar from '../../../../components/Toolbar';

export default function Credits({ status }) {
  const { id } = useParams();
  return (
    <Toolbar getExport={exportCredits}>
      {
          (query, credits, handleCreditsToExport) => (
            <CreditManager
              project={id}
              query={query}
              filter={status}
              handleCreditsToExport={handleCreditsToExport}
              creditsToExport={credits}
            />
          )
       }
    </Toolbar>
  );
}
