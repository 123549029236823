import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvestmentsManagerDataDropdown from './InvestmentsManagerDataDropdown';
import { addPopup } from '../../../../../../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  refundRequest: state.investors.refunds.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addPopup }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentsManagerDataDropdown);
