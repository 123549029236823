import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvoiceSumup from './InvoiceSumup';
import { checkIfLoading } from '../../../../../services/store/features/uiAction/selectors';
import * as types from '../../../../../services/store/features/invoices/types';
import { addPopup } from '../../../../../services/store/features/popup/actions';

const mapStateToProps = (state) => ({
  isLoading: checkIfLoading(state, [types.FETCH_INVOICES]),
  project: state.invoices.invoices.project,
  invoicesId: state.invoices?.invoices?.invoices?.map((invoice) => invoice.id),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addPopup }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSumup);
