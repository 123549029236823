import React, { useState, useEffect } from 'react';
import Pagination from '../../../../components/Pagination';
import Loading from '../../../../components/library/Loading';
import * as statuses from '../../../../services/store/states';
import CreditManagerTable from './CreditManagerTable';
import styles from './CreditManager.module.css';

export default function CreditManager({
  project = undefined, status = statuses.IDLE, credits = undefined,
  isLoading = false, filter = undefined, query = '', actions = {},
  handleCreditsToExport = undefined, creditsToExport = [],
}) {
  const [page, setPage] = useState(1);
  useEffect(() => {
    actions.fetchAllCredits({
      filter, query, page, project,
    });
  }, [filter, query, page, project]);

  if (status === statuses.IDLE || isLoading) return <Loading />;

  if (status === statuses.FAIL || credits.credits.length === 0) {
    return (
      <div className={styles.error}>
        <p> No credits available </p>
      </div>
    );
  }

  return (
    <>
      <CreditManagerTable
        data={credits.credits}
        creditsToExport={creditsToExport}
        handleCreditsToExport={handleCreditsToExport}
      />
      <Pagination
        currentPage={page}
        total={credits.total}
        setPage={setPage}
      />
    </>
  );
}
