import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Invoice from './Invoice';
import { addPopup } from '../../../../services/store/features/popup/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addPopup }, dispatch),
});

export default connect(null, mapDispatchToProps)(Invoice);
