import React from 'react';
import CustomerLayout from './CustomerLayout';
import CustomerInvoice from './CustomerInvoice';
import CustomerPayment from './CustomerPayment';
import CustomerCredits from './CustomerCredits';
import CustomerRefund from './CustomerRefund';

export default function Customer({ actions, status, invoices }) {
  const CUSTOMER_TAB = {
    invoices: <CustomerInvoice data={invoices} actions={actions} />,
    payments: <CustomerPayment actions={actions} />,
    credits: <CustomerCredits />,
    refund: <CustomerRefund />,
  };

  return (
    <CustomerLayout
      balance={invoices?.project?.balance || 0}
      invoices={invoices?.invoices
        ?.filter((invoice) => invoice.balance > 0)
        .map((invoice) => invoice.id) || []}
      actions={actions}
      status={status}
    >
      {
        (tabToDisplay) => (CUSTOMER_TAB[tabToDisplay])
      }
    </CustomerLayout>
  );
}
