import { isEqual } from 'lodash';
import * as types from './types';
import { startAction, stopAction } from '../uiAction/actions';
import * as statuses from '../../states';
import { addToast } from '../toasts/actions';
import { getAllCredits } from '../../../network/api/invoices';

function formatCreditsByProject(data, projectId) {
  const {
    balance,
    total_amount_discount: discount,
    total_amount_payed: amountPayed,
    total_amount_with_taxes: amountWithTaxes,
    total_amount_without_taxes: amountWithoutTaxes,
    project_info: projectDetails = {},
    ...credits
  } = data;

  const { lead_first_name: firstName = '', lead_last_name: lastName = '' } = projectDetails;

  return Object.entries(credits).map(([creditsId, credit]) => ({
    ...credit,
    ...projectDetails,
    customerName: (firstName && lastName) ? `${firstName} ${lastName}` : 'Unknown',
    id: creditsId,
    uiStatus: statuses.IDLE,
    project: projectId,
  }));
}

function formatCreditsData(projects) {
  return Object.entries(projects)
    .reduce(
      (acc, [projectId, project]) => [...acc, ...formatCreditsByProject(project, projectId)], [],
    );
}

// eslint-disable-next-line import/prefer-default-export
export function fetchAllCredits({
  filter, query, page, project,
}) {
  return async (dispatch, getState) => {
    const { previousFetch } = getState().credits;

    if (isEqual(previousFetch, {
      filter, query, page, project,
    })) return;

    dispatch(startAction(types.FETCH_CREDITS));
    try {
      const { data } = await getAllCredits(page, filter, query, project);
      dispatch({
        type: types.FETCH_CREDITS,
        payload: {
          credits: {
            credits: formatCreditsData(data.data),
            total: data.total,
          },
          fetch: {
            filter, query, page, project,
          },
        },
      });
    } catch (error) {
      dispatch(addToast({
        type: 'error',
        title: 'Credits can\'t be fetched',
        description: `A technical error occured : ${error}`,
      }));
      dispatch({ type: types.FETCH_CREDITS_FAIL, payload: error });
    } finally {
      dispatch(stopAction(types.FETCH_CREDITS));
    }
  };
}
