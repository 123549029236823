import * as types from './types';
import toastFactory from './factory';

export function addToast(properties) {
  return (dispatch) => {
    const toast = toastFactory(properties);
    dispatch({ type: types.ADD_TOAST, payload: toast });
  };
}

export function removeToast(id) {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_TOAST, payload: id });
  };
}
