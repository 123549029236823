import * as types from './types';
import * as statuses from '../../states';

const initialState = {
  status: statuses.IDLE,
  previousFetch: {},
  credits: {
    total: 0,
    credits: [],
  },
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_CREDITS:
      return {
        ...state,
        credits: payload.credits,
        status: statuses.SUCCESS,
        previousFetch: payload.fetch,
      };
    case types.FETCH_CREDITS_FAIL:
      return { ...state, status: statuses.FAIL, error: payload };
    default:
      return state;
  }
}
