import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Language from './language';

import translationEN from './i18n/en.json';
import translationFR from './i18n/fr.json';

const defaultLanguage = Language.FR;

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: {
      format: (value, format) => {
        if (format === 'currency-fr') {
          return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
        }
        if (format === 'currencyNoCents-fr') {
          return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
            maximumFractionDigits: 0,
          }).format(value);
        }
        if (format === 'currency-en') {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
            maximumFractionDigits: 0,
          }).format(value);
        }
        if (format === 'currencyNoCents-en') {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(value);
        }
        if (format === 'number-fr') {
          return new Intl.NumberFormat('fr-FR').format(value);
        }
        if (format === 'number-en') {
          return new Intl.NumberFormat('en-US').format(value);
        }
        return value;
      },
      escapeValue: false, // react already safes from xss
    },
    resources,
    lng: navigator.language || navigator.userLanguage,
    fallbackLng: defaultLanguage,

    keySeparator: '.', // to support nested translations
  });

export default i18n;
