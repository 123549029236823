import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close, GetApp, Search } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import * as statuses from '../../services/store/states';
import styles from './Toolbar.module.css';
import Button from '../library/Button/Button';

export default function Toolbar({
  getExport, children, options = null, className, searchable = true,
}) {
  const [query, setQuery] = useState('');
  const [submittedQuery, setSubmittedQuery] = useState('');
  const [idToExport, setIdToExport] = useState([]);
  const [exportState, setExportState] = useState({ status: statuses.IDLE, error: null, url: null });

  const { t } = useTranslation();

  const handleInvoicesToExport = (arrayOfId) => {
    setExportState({ status: statuses.IDLE, error: null, url: null });
    if (arrayOfId.length === 0 || arrayOfId.length > 1) {
      setIdToExport(arrayOfId);
    } else {
      const id = arrayOfId[0];
      if (idToExport.includes(id)) {
        setIdToExport((state) => state.filter((invoice) => invoice !== id));
      } else {
        setIdToExport((state) => [...state, id]);
      }
    }
  };

  const exportSelectedInvoices = async () => {
    setExportState((state) => ({ ...state, status: statuses.LOADING }));
    try {
      const { data } = await getExport(idToExport);
      setExportState((state) => ({
        ...state,
        status: statuses.SUCCESS,
        url: data.url,
        error: null,
      }));
    } catch (error) {
      setExportState((state) => ({ ...state, status: statuses.FAIL, error }));
    }
  };

  const downloadSelectedInvoice = () => {
    if (exportState.status === statuses.SUCCESS) {
      const link = document.createElement('a');
      link.href = exportState.url;
      link.setAttribute('download', 'invoices');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportState({ status: statuses.IDLE, error: null, url: null });
      setIdToExport([]);
    }
  };

  return (
    <main className={styles.main}>
      <div className={`${className} ${styles.toolbar}`}>
        {
          (searchable)
            ? (
              <div className={styles.row}>
                <div className={styles.searchbar}>
                  <Search className={styles.icon} />
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={t('searchbar.placeholder')}
                  />
                </div>
                {
                  (submittedQuery === '')
                    ? (
                      <Button
                        icon={<Search className={styles.searchIcon} />}
                        label=""
                        onButtonClick={() => setSubmittedQuery(query)}
                        className={styles.button}
                        name="search"
                      />
                    )
                    : (
                      <Button
                        icon={<Close className={styles.closeIcon} />}
                        label=""
                        onButtonClick={() => {
                          setQuery('');
                          setSubmittedQuery('');
                        }}
                        className={styles.button}
                        name="cancel"
                      />
                    )
                }
              </div>
            )
            : <div />
        }
        <div className={`${styles.row} ${styles.buttonRow}`}>
          {
            options && options(idToExport)
          }
          {
            (exportState.status !== statuses.SUCCESS)
              ? (
                <Button
                  type="custom"
                  className={styles.custom}
                  icon={(exportState.status !== statuses.LOADING)
                    ? <GetApp />
                    : <CircularProgress />}
                  label={(exportState.status !== statuses.LOADING)
                    ? 'Export selection'
                    : 'Export ongoing ...'}
                  onButtonClick={exportSelectedInvoices}
                  disabled
                  // disabled={idToExport.length === 0}
                />
              )
              : (
                <Button
                  type="custom"
                  className={styles.custom}
                  icon={(exportState.status !== statuses.LOADING)
                    ? <GetApp />
                    : <CircularProgress />}
                  label="Download selection"
                  onButtonClick={downloadSelectedInvoice}
                />
              )
          }
        </div>
      </div>
      { children(submittedQuery, idToExport, handleInvoicesToExport) }
    </main>
  );
}
