import { combineReducers } from 'redux';
import rolesReducer from './features/roles/reducer';
import toastsReducer from './features/toasts/reducer';
import invoicesReducer from './features/invoices/reducer';
import uiReducer from './features/uiAction/reducer';
import popupReducer from './features/popup/reducer';
import creditsReducer from './features/credits/reducer';
import customersReducer from './features/customers/reducer';
import investorsReducer from './features/investors/reducer';

const rootReducer = combineReducers({
  roles: rolesReducer,
  toasts: toastsReducer,
  popups: popupReducer,
  invoices: invoicesReducer,
  credits: creditsReducer,
  customers: customersReducer,
  investors: investorsReducer,
  ui: uiReducer,
});

export default rootReducer;
