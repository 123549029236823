import React, { useState } from 'react';
import { MoreVert } from '@material-ui/icons';

import Checkbox from '../../../../../../components/library/Input/Checkbox/Checkbox';
import InvoiceManagerDataDropdown from './InvoiceManagerDataDropdown';
import styles from './InvoiceManagerData.module.css';
import InvoiceManagerDataProduct from './InvoiceManagerDataProduct';

function InvoiceManagerDataRow({ invoice, isChecked, onCheckedChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isProductOpen, setProductsOpen] = useState(false);

  const handleOpenProducts = () => setProductsOpen((state) => !state);

  return (
    <>
      <div className={styles.row}>
        <Checkbox checked={isChecked} onChange={() => onCheckedChange([invoice.id])} />
        <p className={styles.title} />
        <p className={styles.title}>Customer Name</p>
        <p>{ invoice.customerName }</p>
        <p className={styles.title}>Invoice ID</p>
        {
          (invoice?.products?.length > 1)
            ? (
              <button
                type="button"
                className={styles.button}
                onClick={handleOpenProducts}
              >
                { invoice.id}
              </button>
            ) : <p>{ invoice.id }</p>
        }
        <p className={styles.title}>Amount excl. tax</p>
        <p>{ invoice.amountHT }</p>
        <p className={styles.title}>Amount incl. tax</p>
        <p>{ invoice.amountTTC }</p>
        <p className={styles.title}>Credits</p>
        <p>{ invoice.discount_amount }</p>
        <p className={styles.title}>Balance</p>
        <p className={(invoice.balance > 0) ? styles.wait : ''}>{ invoice.balance }</p>
        <p className={styles.title}>Status</p>
        {
          (invoice.balance > 0)
            ? <p className={`${styles.status} ${styles.wait}`}>Wait</p>
            : <p className={`${styles.status} ${styles.paid}`}>Paid</p>
        }
        <p className={styles.title}>Date</p>
        <p>{ new Date(invoice.issue_date).toLocaleDateString('fr') }</p>
        <p className={styles.title} />
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className={styles.more}
        >
          <MoreVert />
        </button>
        {
          (isOpen)
          && (
            <InvoiceManagerDataDropdown
              invoice={invoice}
              close={() => setIsOpen(false)}
            />
          )
        }
      </div>
      {
        (isProductOpen)
        && <InvoiceManagerDataProduct products={invoice.products} />
      }
    </>
  );
}

export default function InvoiceManagerData({ data, invoicesToExport, handleInvoicesToExport }) {
  return (
    <>
      {
        data && data.map((invoice) => (
          <InvoiceManagerDataRow
            key={invoice.id}
            invoice={invoice}
            isChecked={invoicesToExport.includes(invoice.id)}
            onCheckedChange={handleInvoicesToExport}
          />
        ))
      }
    </>
  );
}
