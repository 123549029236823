import React, { useRef } from 'react';
import styles from './PopupManager.module.css';

import useOutsideClickAlert from '../library/hook/clickOutside';

function Popup({
  title, description, close, children,
}) {
  const modal = useRef();
  useOutsideClickAlert(modal, close);
  return (
    <div className={`fadeIn ${styles.background} ${(window.location.pathname.search('admin') === -1) ? styles.fullsize : ''}`}>
      <section className={styles.modal} ref={modal}>
        {
          (title || description)
          && (
            <header>
              <h1>
                { title }
              </h1>
              <p>
                { description }
              </p>
            </header>
          )
        }
        { children(close) }
      </section>
    </div>
  );
}

export default function PopupManager({ popups, actions }) {
  return (
    <>
      {
        popups.map((popup) => (
          <Popup
            key={popup.id}
            title={popup.title}
            description={popup.description}
            close={() => actions.removePopup(popup.id)}
          >
            { popup.children }
          </Popup>
        ))
      }
    </>
  );
}
