import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../../../../components/Table';
import InvestmentsManagerData from './InvestmentsManagerData';

import styles from './InvestmentsManager.module.css';
import AccountLoading from '../../../../../components/library/Loading';

export default function InvestmentsManager({
  data, status, refunds, query, invoicesToExport, handleInvoicesToExport, actions,
}) {
  const { id } = useParams();

  useEffect(() => {
    actions.fetchAllInvestors(id);
  }, []);

  const getCurrentInvestments = (value) => {
    if (!value) return [];
    return (value.filter((investment) => {
      const investmentToStr = Object.values(investment).join(' ').toLowerCase();
      return investmentToStr.search(query.toLowerCase()) !== -1;
    }));
  };

  const getFullname = (value) => `${value.person.first_name} ${value.person.last_name}`;

  const getPhone = (value) => value.person.phone;

  const getStatus = (value) => ((refunds[value.id_stripe] && !refunds[value.id_stripe].is_ayomi && !refunds[value.id_stripe].is_validated) ? 'request' : value.status);

  if (status === 'LOADING' || status === 'IDLE') {
    return (<AccountLoading />);
  }

  if (data.length === 0) {
    return (
      <div className={styles.empty}>
        <p>
          No investments available
        </p>
      </div>
    );
  }

  return (
    <section className={styles.table}>
      <Table
        data={data}
        dataToExport={invoicesToExport}
        handleDataToExport={handleInvoicesToExport}
        columns={[
          {
            label: 'Full name',
            value: 'fullname',
            transform: getFullname,
            type: 'alpha',
          },
          { label: 'Email', value: 'email', type: 'alpha' },
          {
            label: 'Phone',
            value: 'phone',
            type: 'phone',
            transform: getPhone,
          },
          {
            label: 'Status',
            value: 'status',
            transform: getStatus,
            type: 'alpha',
          },
          { label: 'Date', value: 'date_money_received', type: 'num' },
          { label: 'Amount', value: 'amount', type: 'num ' },
        ]}
        exportColumn="invoice"
      >
        {
          (currentData) => (
            <InvestmentsManagerData
              data={getCurrentInvestments(currentData)}
              invoicesToExport={invoicesToExport}
              handleInvoicesToExport={handleInvoicesToExport}
            />
          )
        }
      </Table>
    </section>
  );
}
