import React from 'react';

import InvoiceLayout from './InvoiceLayout';
import InvoiceToolbar from './InvoiceToolbar';
import InvoiceManager from './InvoiceManager';

export default function Invoice() {
  return (
    <InvoiceLayout>
      {
        (filter) => (
          <InvoiceToolbar>
            {
              (query, invoices, handleInvoicesToExport) => (
                <InvoiceManager
                  filter={filter}
                  query={query}
                  handleInvoicesToExport={handleInvoicesToExport}
                  invoicesToExport={invoices}
                />
              )
            }
          </InvoiceToolbar>
        )
      }
    </InvoiceLayout>
  );
}
