import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@material-ui/icons';

import Checkbox from '../../../../../../components/library/Input/Checkbox/Checkbox';
import CreditManagerDataDropdown from './CreditManagerDataDropdown';
import styles from './CreditManagerData.module.css';

function CreditManagerDataRow({
  credit, hideCustomer, hideOption, isChecked, onCheckedChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className={styles.row}>
      <Checkbox checked={isChecked} onChange={() => onCheckedChange([credit.id])} />
      <p className={styles.title} />
      <p hidden={hideCustomer} className={(!hideCustomer) && styles.title}>Customer</p>
      <p hidden={hideCustomer}>{ credit.customerName }</p>
      <p className={styles.title}>{t('invoices.reference')}</p>
      <p>{ credit.id }</p>
      <p className={styles.title}>{t('invoices.invoice')}</p>
      <p>{ credit?.invoices?.join(',') }</p>
      <p className={styles.title}>{t('invoices.credits')}</p>
      <p>{ credit.discount_amount }</p>
      <p className={styles.title}>{t('invoices.date')}</p>
      <p>{ new Date(credit.issue_date).toLocaleString() }</p>
      <p className={styles.title} />
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        hidden={hideOption}
        className={styles.more}
      >
        <MoreVert />
      </button>
      {
          (isOpen)
          && (
            <CreditManagerDataDropdown
              credit={credit}
              close={() => setIsOpen(false)}
            />
          )
        }
    </div>
  );
}

export default function CreditManagerData({
  data, hideCustomer = false, hideOption = false, creditsToExport, handleCreditsToExport,
}) {
  return (
    <>
      {
        data && data.map((credit) => (
          <CreditManagerDataRow
            key={credit.id}
            credit={credit}
            isChecked={creditsToExport.includes(credit.id)}
            onCheckedChange={handleCreditsToExport}
            hideCustomer={hideCustomer}
            hideOption={hideOption}
          />
        ))
      }
    </>
  );
}
