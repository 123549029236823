/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, useHistory,
} from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import verifyToken from '../services/store/features/roles/actions';

import Loading from '../components/library/Loading';
import Invoice from '../views/Admin/Invoice';
import Navigation from '../components/Navigation';
import Credit from '../views/Admin/Credit';
import Customers from '../views/Admin/Customers';
import CustomerInvoice from '../views/Admin/CustomerInvoice';
import Customer from '../views/Customer';
import Error from '../views/Error';

const ROUTES = [
  {
    path: '/admin/customers/:id',
    component: CustomerInvoice,
    auth: true,
  },
  {
    path: '/admin/customers',
    component: Customers,
    auth: true,
  },
  {
    path: '/admin/credits',
    component: Credit,
    auth: true,
  },
  {
    path: '/admin',
    component: Invoice,
    auth: true,
    roles: [/* 'INVOICE_CAMPAIGN_GET' */],
  },
  {
    path: '/customers/:id',
    component: Customer,
    auth: true,
  },
  {
    path: '/',
    component: Error,
    auth: false,
  },
];

export default function RouteConfig() {
  return (
    <Router>
      <div className="main">
        <Switch>
          {ROUTES.map((route) => (
            <ConnectedRouteWithSubRoutes key={route.path} {...route} />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

function RouteWithSubRoutes({
  path, auth, routes, status, roles, authorizedRole, ...route
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth) {
      dispatch(verifyToken());
    }
  }, []);

  useEffect(() => {
    if (roles && roles.length > 0 && status === 'SUCCEEDED') {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of roles) {
        if (!authorizedRole.includes(role)) {
          if (history.length === 1) {
            document.location.assign('https//auth.ayomi.fr/');
          } else {
            history.goBack();
          }
        }
      }
    }
  }, [status]);

  if (!auth || (auth && status === 'SUCCEEDED')) {
    return (
      <>
        {(path.search('admin') !== -1) && <Navigation />}
        <Route
          path={path}
          render={(props) => (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={routes} />
          )}
        />
      </>
    );
  }

  return <Loading />;
}

const mapStateToProps = (state) => ({
  authorizedRole: state.roles.roles,
  status: state.roles.status,
});

const ConnectedRouteWithSubRoutes = connect(mapStateToProps)(RouteWithSubRoutes);
