import React from 'react';
import Layout from '../../../components/Layout';
import CustomersManager from './CustomersManager';

export default function Customers() {
  return (
    <Layout
      title="Customers"
      showSearchbar
    >
      { (query) => (
        <CustomersManager query={query} />
      ) }
    </Layout>
  );
}
