import React, { useEffect, useState } from 'react';
import { generateInvoiceByUrl } from '../../services/network/api/invoices';
import DocumentsVisualisation from '../../components/DocumentsVisualisation';

export default function MediaViewer({ selectedDocument, actions }) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFile = async () => {
      if (selectedDocument) {
        try {
          const { data } = await generateInvoiceByUrl(selectedDocument.url);
          setFile(window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' })));
        } catch (err) {
          setError(err.response.data.action || err);
        }
      }
    };
    if (!selectedDocument) setFile(null);
    else {
      fetchFile();
    }
  }, [selectedDocument]);

  if (!selectedDocument) return null;

  return (
    <DocumentsVisualisation
      selectedDocument={{ title: selectedDocument.id, url: file }}
      actions={actions}
      isLoading={selectedDocument && !file && !error}
      error={error}
      isAdmin={window.location.pathname.search('admin') !== -1}
    />
  );
}
