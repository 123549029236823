import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomerPaymentManager from './CustomerPaymentManager';
import { fetchAllInvoices } from '../../../../services/store/features/invoices/actions';

const mapStateToProps = (state) => ({
  data: state.invoices.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchAllInvoices }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentManager);
