import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RefundCreation from './RefundCreation';
import { ayomiRefund } from '../../services/store/features/investors/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ayomiRefund }, dispatch),
});

export default connect(null, mapDispatchToProps)(RefundCreation);
